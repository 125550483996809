import { Box, Center, Image, StackDivider, VStack } from '@efishery/onefish';
import { ReactNode } from 'react';

type ImagePromptProps = {
  imgUrl: string;
  title?: ReactNode;
  subtitle?: ReactNode;
};

export const ImagePrompt = ({ imgUrl, title, subtitle }: ImagePromptProps) => {
  return (
    <Box width={'full'}>
      <Center py={2}>
        <VStack>
          <Image src={imgUrl} alt="prompt" />
          {title}
          {subtitle}
        </VStack>
      </Center>
      <StackDivider borderWidth={1} borderStyle={'dashed'} />
    </Box>
  );
};
