import { Box, Divider, Heading, Stack, Text, VStack } from '@efishery/onefish';
import { isInvoiceExist } from 'components';
import { OrderDetail } from 'types';
import { currencyIntl, startCase } from 'utils';
import { transformInvoiceLines } from '../../utils/transform';
import { comodity, comodityFineWord } from 'features/transport/utils';

type DetailPengirimanProps = {
  deliveryDetail?: OrderDetail;
};

export const DetailPengiriman = ({ deliveryDetail }: DetailPengirimanProps) => {
  if (!deliveryDetail) {
    return null;
  }

  const lines = isInvoiceExist(deliveryDetail.invoice_status)
    ? transformInvoiceLines(deliveryDetail.invoice_lines)
    : deliveryDetail.order_lines;

  return (
    <VStack w="full" align="start" spacing="4">
      <Heading as="h2" size="xs">
        Detail Pengiriman
      </Heading>
      {(lines ?? []).map((orderLine, index) => {
        return (
          <Box key={orderLine.id} fontSize="sm" w="full">
            <Heading as="h3" size="xs">
              {orderLine.product_name}
            </Heading>
            <Stack
              direction="row"
              h={orderLine.receive_damaged_quantity ? '112px' : '66px'}
              w="full"
              p="3"
              pr="0"
            >
              <Divider orientation="vertical" variant="thick" />

              <VStack w="full" spacing="1" align="start">
                <VStack w="full" spacing="0" align="start">
                  <Stack direction="row" w="full" justify="space-between">
                    <Text color="grey">
                      {comodity(deliveryDetail?.business_type) +
                        ' ' +
                        startCase(
                          comodityFineWord(deliveryDetail?.business_type),
                        )}
                    </Text>
                    <Text fontWeight="medium">
                      {currencyIntl(orderLine.total_good_product_final_price)}
                    </Text>
                  </Stack>
                  <Text fontWeight="medium">
                    {orderLine.receive_good_quantity} {orderLine.product_uom} x{' '}
                    {currencyIntl(orderLine.good_product_final_price)}
                  </Text>
                </VStack>

                {orderLine.receive_damaged_quantity > 0 && (
                  <VStack w="full" spacing="0" align="start">
                    <Stack direction="row" w="full" justify="space-between">
                      <Text color="grey">
                        {comodity(deliveryDetail?.business_type) + ' Rusak'}
                      </Text>
                      <Text fontWeight="medium">
                        {currencyIntl(
                          orderLine.total_damaged_product_final_price,
                        )}
                      </Text>
                    </Stack>
                    <Text fontWeight="medium">
                      {orderLine.receive_damaged_quantity}{' '}
                      {orderLine.product_uom} x{' '}
                      {currencyIntl(orderLine.damaged_product_final_price)}
                    </Text>
                  </VStack>
                )}
              </VStack>
            </Stack>

            {index !== (deliveryDetail?.order_lines ?? []).length - 1 ? (
              <Divider variant="thickDashed" mt="1" />
            ) : (
              <Divider variant="thick" mt="1" />
            )}
          </Box>
        );
      })}
    </VStack>
  );
};
