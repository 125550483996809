import { Dispatch, SetStateAction } from 'react';
import { GEO_PERMISSION_KEY, HIDE_POPOVER_PAGES } from './const';
import { PermissionState } from './types';

export const isMobile = () => {
  return (
    (navigator && navigator?.maxTouchPoints > 1) ||
    (localStorage && localStorage?.mobile)
  );
};

export const saveLocationQueryToState = async (
  setGeoState: Dispatch<SetStateAction<PermissionState | null>>,
) => {
  const permission = await navigator?.permissions?.query({
    name: 'geolocation',
  });

  const permState = await permission?.state;
  setGeoState(permState ?? 'prompt');
};

export const geoSavePermission = (
  permission: PermissionState,
  keySuffix?: string,
) => {
  localStorage.setItem(generateStoreKey(keySuffix), permission);
};

export const geoFetchPermission = (keySuffix?: string) => {
  if (!localStorage) return null;
  return localStorage.getItem(generateStoreKey(keySuffix));
};

export const geoSavePosition = (
  coords: Partial<GeolocationCoordinates>,
  positionKey?: string,
  pageName?: string,
) => {
  if (localStorage) {
    geoSavePermission('granted', pageName);
    if (positionKey) {
      localStorage.setItem(
        positionKey,
        JSON.stringify({
          lat: coords.latitude,
          lng: coords.longitude,
        }),
      );
    }
  }
};

export const isHideInitialPopover = (pageName?: string) => {
  if (pageName) {
    for (const page of HIDE_POPOVER_PAGES) {
      if (pageName.indexOf(page) > -1) {
        return true;
      }
    }
  }
  return false;
};

export const generateStoreKey = (keySuffix?: string) => {
  return keySuffix ? GEO_PERMISSION_KEY + '-' + keySuffix : GEO_PERMISSION_KEY;
};

export const geoFetchLocationFromStorage = (id?: string) => {
  const coords = localStorage.getItem(id ?? '');
  if (coords) {
    return JSON.parse(coords);
  }
  return null;
};

export const geoRemoveLocationFromStorage = (id?: string) => {
  localStorage.removeItem(id ?? '');
};
