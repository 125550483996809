import { BusinessType } from 'types';

export const comodity = (businessType?: BusinessType) => {
  switch (businessType) {
    case 'FISH_DOWNSTREAM':
      return 'Ikan';
    case 'SHRIMP_DOWNSTREAM':
      return 'Udang';
    default:
      return 'Ikan';
  }
};

export const comodityDefectWord = (businessType?: BusinessType) => {
  switch (businessType) {
    case 'FISH_DOWNSTREAM':
      return 'mati';
    case 'SHRIMP_DOWNSTREAM':
      return 'rusak';
    default:
      return 'mati';
  }
};

export const comodityFineWord = (businessType?: BusinessType) => {
  switch (businessType) {
    case 'FISH_DOWNSTREAM':
      return 'hidup';
    case 'SHRIMP_DOWNSTREAM':
      return 'baik';
    default:
      return 'hidup';
  }
};
