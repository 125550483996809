import dayjs from 'dayjs';

export function capitalize(word?: string) {
  if (!word) return '';
  return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
}

export function startCase(text?: string) {
  if (!text) return '';
  return text.split(' ').map(capitalize).join(' ');
}

export function toKebabCase(inputString: string): string {
  const lowerCaseString = inputString.toLowerCase();
  return lowerCaseString.replace(/\s+/g, '-');
}

export function toTitleCase(s: string) {
  return s
    ? s
        .split(' ')
        .map(word => {
          return word.length > 0
            ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            : word;
        })
        .join(' ')
    : '';
}

export function formatDateTimeRange(startDate: string, endDate: string) {
  const format = 'D MMM YYYY HH:mm';
  const dateStart = dayjs.utc(startDate.trim()).local();
  const dateEnd = dayjs.utc(endDate.trim()).local();

  const timeStartEnd = `${dateStart.format('HH:mm')} - ${dateEnd.format(
    'HH:mm',
  )}`;

  if (dateStart.year() === dateEnd.year()) {
    if (dateStart.month() === dateEnd.month()) {
      if (dateStart.day() === dateEnd.day()) {
        return `${dateStart.format('D MMM YYYY')} jam ${timeStartEnd}`;
      } else {
        return `${dateStart.format('D')} - ${dateEnd.format(
          'D MMM YYYY',
        )} ${timeStartEnd}`;
      }
    } else {
      return `${dateStart.format('D MMM HH:mm')} - ${dateEnd.format(format)}`;
    }
  }

  return `${dateStart.format(format)} - ${dateEnd.format(format)}`;
}

export function isEnvTrue(env: string | number | boolean) {
  if (typeof env === 'string') {
    return ['true', '1', 'yes', 'on'].includes(env.toLowerCase());
  }
  if (typeof env === 'number') {
    return env === 1;
  }
  return env === true;
}
