import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Text,
  VStack,
} from '@efishery/onefish';
import { MapPinLine } from '@onefish/icons-react';

import {
  Address,
  Card,
  ComodityDescription,
  ConsignmentNoteButton,
  Description,
  Invoice,
  PaymentLink,
  composeInvoiceStatusMsg,
} from 'components';
import type { OrderDetail } from 'types';
import { startCase } from 'utils';
import { Link } from 'react-router-dom';

import { formatEta } from 'pages/pickup/helpers';
import { getAttachmentLink } from 'utils/order';

import { formatShipDate } from '../../../helpers';
import { Attachment } from '../Attachment';
import { OriginSection } from '../OriginSection';
import { isShowInvoice, renderReceivingReport } from './utils';
import { PAYMENT_TYPE_LABEL } from 'features/transport/constants';

type ShippingOrderProps = {
  deliveryDetail?: OrderDetail;
  'data-testid-prefix'?: string;
};

export const ShippingOrder = ({
  deliveryDetail,
  'data-testid-prefix': dataTestIdPrefix = '',
}: ShippingOrderProps) => {
  const { etaDate, etaTimeStart, etaTimeEnd } = formatEta(
    deliveryDetail?.expected_delivery_at,
    deliveryDetail?.expected_delivery_end_at,
  );

  const isShuttle = deliveryDetail?.shipment_type === 'MID_MILE';

  // react strict mode workaround
  const proofOfPayment = getAttachmentLink(
    deliveryDetail?.attachments,
    'PROOF_OF_PAYMENT',
  );
  const consignmentNote = getAttachmentLink(
    deliveryDetail?.attachments,
    'CONSIGNMENT_NOTE',
  );
  const receivingReport = getAttachmentLink(
    deliveryDetail?.attachments,
    'RECEIVING_REPORT',
  );
  const productCondition = getAttachmentLink(
    deliveryDetail?.attachments,
    'PRODUCT_CONDITION',
  );
  const buyerAcceptance = getAttachmentLink(
    deliveryDetail?.attachments,
    'BUYER_ACCEPTANCE',
  );
  const hardCashPaymentReceipt = getAttachmentLink(
    deliveryDetail?.attachments,
    'PROOF_OF_PAYMENT_HARD_CASH',
  );

  const receivingReportFileExtension = receivingReport?.split('.').pop();
  const isInvoice = isShowInvoice(
    deliveryDetail?.shipment_type,
    deliveryDetail?.invoice_status,
  );

  const noInvoiceExists =
    deliveryDetail?.shipment_type === 'LAST_MILE' &&
    deliveryDetail?.invoice_status !== 'PAID' &&
    deliveryDetail?.invoice_status !== 'CREATE_SUCCESS' &&
    !deliveryDetail?.invoice_number;

  return (
    <VStack w="full" alignItems="stretch" spacing="3" mb="6">
      <Card p="4">
        <VStack w="full" alignItems="stretch" spacing="3">
          <Heading as="h2" size="md">
            Detail Pengiriman
          </Heading>

          <Flex bg="brand.70" borderRadius="md" p="3" color="brand.600">
            <Box w={consignmentNote ? '80%' : '100%'}>
              <Text fontWeight="semibold">
                Diantarkan ke {startCase(deliveryDetail?.dest_name) || '-'}
              </Text>
              <HStack>
                <MapPinLine />
                <Text fontSize="sm">Selesai</Text>
              </HStack>
            </Box>
            <ConsignmentNoteButton link={consignmentNote} />
          </Flex>

          <Text fontSize="md" lineHeight="5" color="grey.500">
            Pengiriman tanggal <strong>{etaDate}</strong> jam{' '}
            <em>
              {etaTimeStart} - {etaTimeEnd}
            </em>
          </Text>
        </VStack>
      </Card>

      <Address
        address={deliveryDetail?.dest_address}
        city={deliveryDetail?.dest_city_name}
        province={deliveryDetail?.dest_province_name}
      />

      <Card p="4">
        <Description
          name="Tanggal Pengantaran"
          value={formatShipDate(deliveryDetail?.delivery_success_at ?? '')}
        />
      </Card>

      {isInvoice && (
        <Card p="4">
          <VStack spacing="3" w="full" alignItems="stretch">
            <Description name="Detil harga dan tagihan sesuai dengan">
              <></>
            </Description>
            <Description
              name="Invoice: "
              value={deliveryDetail?.invoice_number ?? '-'}
              data-testid={`${dataTestIdPrefix}txt_invoice_number`}
            />
            <Invoice
              invoiceLines={deliveryDetail?.invoice_lines}
              promo={{
                code: deliveryDetail?.promo_code ?? '',
                amount: deliveryDetail?.invoice_total_discount ?? 0,
              }}
              grandTotal={deliveryDetail?.invoice_grand_total_nett}
              data-testid-prefix={dataTestIdPrefix}
              businessType={deliveryDetail?.business_type}
            />
          </VStack>
        </Card>
      )}

      {!isInvoice &&
        (deliveryDetail?.order_lines ?? []).map((orderLine, index) => {
          return (
            <Card p="4" key={orderLine.id}>
              <VStack w="full" alignItems="stretch" spacing="2">
                <ComodityDescription
                  name="Jenis"
                  businessType={deliveryDetail?.business_type}
                  value={orderLine?.product_name}
                  data-testid={`${dataTestIdPrefix}txt_product_name-${
                    index + 1
                  }`}
                />
                <ComodityDescription
                  name="Ukuran"
                  businessType={deliveryDetail?.business_type}
                  value={orderLine?.product_uom}
                  data-testid={`${dataTestIdPrefix}txt_product_size-${
                    index + 1
                  }`}
                />

                <Divider variant="dashed" />

                <Description
                  name="Jumlah yang Dibawa"
                  value={orderLine?.carried_quantity}
                  format="thousand-separator"
                  unit="kg"
                  data-testid={`${dataTestIdPrefix}txt_carried_quantity-${
                    index + 1
                  }`}
                />

                {!isShuttle && (
                  <>
                    <Divider variant="dashed" />

                    <ComodityDescription
                      name="Total diterima dengan kondisi baik"
                      businessType={deliveryDetail?.business_type}
                      value={orderLine.receive_good_quantity}
                      format="thousand-separator"
                      unit="kg"
                      data-testid={`${dataTestIdPrefix}txt_received_good_quantity-${
                        index + 1
                      }`}
                    />

                    <ComodityDescription
                      name="Total diterima dengan kondisi kurang baik"
                      businessType={deliveryDetail?.business_type}
                      value={orderLine.receive_damaged_quantity}
                      format="thousand-separator"
                      unit="kg"
                      data-testid={`${dataTestIdPrefix}txt_received_damaged_quantity-${
                        index + 1
                      }`}
                    />

                    <Divider variant="dashed" />

                    <ComodityDescription
                      name="Harga dengan kondisi baik"
                      businessType={deliveryDetail?.business_type}
                      format="intl-currency-idr"
                      value={orderLine.good_product_final_price}
                      data-testid={`${dataTestIdPrefix}txt_good_product_final_price-${
                        index + 1
                      }`}
                    />

                    <ComodityDescription
                      name="Harga dengan kondisi kurang baik"
                      businessType={deliveryDetail?.business_type}
                      format="intl-currency-idr"
                      value={orderLine.damaged_product_final_price}
                      data-testid={`${dataTestIdPrefix}txt_damaged_product_final_price-${
                        index + 1
                      }`}
                    />

                    <Divider variant="dashed" />

                    <ComodityDescription
                      name="Total Harga kondisi baik"
                      businessType={deliveryDetail?.business_type}
                      format="intl-currency-idr"
                      value={orderLine.total_good_product_final_price}
                      data-testid={`${dataTestIdPrefix}txt_total_good_product_final_price-${
                        index + 1
                      }`}
                    />
                    <ComodityDescription
                      name="Total Harga kondisi kurang baik"
                      businessType={deliveryDetail?.business_type}
                      format="intl-currency-idr"
                      value={orderLine.total_damaged_product_final_price}
                      data-testid={`${dataTestIdPrefix}txt_total_damaged_product_final_price-${
                        index + 1
                      }`}
                    />

                    <Divider variant="dashed" />

                    <Description
                      name="Sub Total"
                      format="intl-currency-idr"
                      value={
                        (orderLine?.total_good_product_final_price ?? 0) +
                        (orderLine?.total_damaged_product_final_price ?? 0)
                      }
                      data-testid={`${dataTestIdPrefix}txt_subtotal-${
                        index + 1
                      }`}
                    />
                  </>
                )}
              </VStack>
            </Card>
          );
        })}

      <Card p="4">
        <HStack justify="space-between" align="start">
          <Text fontWeight="semibold" mb={2}>
            Detail Pembayaran
          </Text>
          {hardCashPaymentReceipt && (
            <Link to={`/payment-receipt/show/${deliveryDetail?.id}`}>
              <Button variant="link" fontSize="xs">
                Lihat Bukti Bayar
              </Button>
            </Link>
          )}
        </HStack>

        <VStack w="full" alignItems="stretch" spacing="2">
          <Description name="No. Referensi" value={deliveryDetail?.so_number} />
          <Description
            name="No. Invoice"
            value={!noInvoiceExists ? deliveryDetail?.invoice_number : '-'}
          />
          <Description
            name="Metode Pembayaran"
            value={
              deliveryDetail?.payment_type
                ? PAYMENT_TYPE_LABEL[deliveryDetail.payment_type]
                : '-'
            }
          />
          <Description
            name="Melalui Bank"
            value={deliveryDetail?.payment_info_bank}
          />
          <Description
            name="Status Pembayaran"
            value={
              deliveryDetail?.invoice_status
                ? composeInvoiceStatusMsg(deliveryDetail.invoice_status)
                : '-'
            }
          />
          {deliveryDetail?.invoice_status !== 'PAID' && (
            <PaymentLink
              link={deliveryDetail?.payment_va_link}
              data-testid-prefix={dataTestIdPrefix}
            />
          )}
        </VStack>
      </Card>

      <Card p="4">
        {!isInvoice ? (
          <>
            <Description
              name="Grand Total"
              value={deliveryDetail?.total_final_price_gross}
              format="intl-currency-idr"
            />
            <Description
              name="Promo"
              value={deliveryDetail?.total_discount}
              format="intl-currency-idr"
            >
              <HStack spacing="1">
                <Text
                  fontSize="sm"
                  textAlign="right"
                  fontWeight="normal"
                  color="brand.600"
                >
                  {!deliveryDetail?.total_discount
                    ? '-'
                    : '- ' +
                      new Intl.NumberFormat('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 2,
                      }).format(deliveryDetail?.total_discount)}
                </Text>
              </HStack>
            </Description>
            <Description
              name="Grand Total Setelah Diskon"
              value={deliveryDetail?.total_final_price_nett}
              format="intl-currency-idr"
            />
          </>
        ) : (
          <Description
            name="Grand Total Invoice"
            value={deliveryDetail?.invoice_grand_total_nett}
            format="intl-currency-idr"
          />
        )}
      </Card>

      {noInvoiceExists && (
        <Card p="4">
          <VStack
            w="full"
            alignItems="stretch"
            spacing="3"
            style={{ marginBottom: '10px' }}
          >
            <Box bg="orange.70" borderRadius="md" p="3" color="orange.600">
              <Text fontWeight="semibold">Invoice belum diterbitkan</Text>
            </Box>
          </VStack>
        </Card>
      )}

      <OriginSection
        origin={deliveryDetail?.origin_name}
        businessType={deliveryDetail?.business_type}
      />

      <Card p="4">
        <Text fontSize="md" fontWeight="bold">
          Surat Receiving Report (RR)
        </Text>
        {(receivingReport ?? '').length > 0 ? (
          renderReceivingReport(
            receivingReportFileExtension,
            receivingReport,
            deliveryDetail,
          )
        ) : (
          <Box borderWidth="1px" borderRadius="lg" p={4} mt={2} color="gray">
            Tidak ada Surat Receiving Report
          </Box>
        )}
      </Card>

      <Attachment
        attachmentType="Bukti Kondisi Produk"
        shipmentType={deliveryDetail?.shipment_type}
        src={productCondition}
      />

      <Attachment
        attachmentType="Bukti Penerimaan Pembeli"
        shipmentType={deliveryDetail?.shipment_type}
        src={buyerAcceptance}
      />

      <Attachment
        attachmentType="Bukti Transfer"
        shipmentType={deliveryDetail?.shipment_type}
        src={proofOfPayment}
      />
    </VStack>
  );
};
