import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Heading,
  Text,
  VStack,
} from '@efishery/onefish';
import {
  ArrowBendUpLeft,
  ArrowBendUpRight,
  CaretRight,
} from '@onefish/icons-react';
import {
  HttpError,
  useGetIdentity,
  useInfiniteList,
  useNavigation,
} from '@refinedev/core';

import { Card, Empty, Error, Loading } from 'components';
import { useFlag } from 'hooks';

import { PAGE_SIZE, PICKUP_TYPES, type InboundShipmentType } from './constants';
import { composePickupMessage, formatPickupStatus } from './helpers';

import type { Order, TransportProfileDriverRes } from 'types';
import { CONFIG } from 'config';

export const PickupList = () => {
  const isPickupEnabled = useFlag(CONFIG.ENABLE_PICKUP);
  const isShuttleEnabled = useFlag(CONFIG.ENABLE_SHUTTLE);
  const pickupQuery = isPickupEnabled ? ['FIRST_MILE'] : [];
  const shuttleQuery = isShuttleEnabled ? ['MID_MILE'] : [];

  const isInboundDisabled = !isPickupEnabled && !isShuttleEnabled;

  const { edit } = useNavigation();
  const { data: driverIdentity } = useGetIdentity<TransportProfileDriverRes>();

  const {
    data: rawDraftPickups,
    isFetching: isLoadingDraftPickup,
    isError: isErrorDraftPickup,
    hasNextPage: hasNextPageDraftPickups,
    fetchNextPage: fetchNextPageDraftPickups,
    isFetchingNextPage: isFetchingNextPageDraftPickups,
  } = useInfiniteList<Order, HttpError>({
    resource: '/driver/shipping-order',
    queryOptions: {
      enabled: Boolean(driverIdentity?.id) && !isInboundDisabled,
    },
    pagination: {
      pageSize: PAGE_SIZE,
    },
    filters: [
      {
        field: 'status',
        operator: 'eq',
        value: 'READY_TO_ORIGIN',
      },
      {
        field: 'shipment_type',
        operator: 'eq',
        value: [...pickupQuery, ...shuttleQuery].join(','),
      },
    ],
  });

  const {
    data: rawActivePickups,
    isFetching: isLoadingActivePickup,
    isError: isErrorActivePickup,
    hasNextPage: hasNextPageActivePickups,
    fetchNextPage: fetchNextPageActivePickups,
    isFetchingNextPage: isFetchingNextPageActivePickups,
  } = useInfiniteList<Order, HttpError>({
    resource: '/driver/shipping-order',
    queryOptions: {
      enabled: Boolean(driverIdentity?.id) && !isInboundDisabled,
    },
    pagination: {
      pageSize: PAGE_SIZE,
    },
    filters: [
      {
        field: 'status',
        operator: 'eq',
        value: 'AT_ORIGIN,INTRANSIT',
      },
      {
        field: 'shipment_type',
        operator: 'eq',
        value: [...pickupQuery, ...shuttleQuery].join(','),
      },
    ],
  });

  const draftPickups = rawDraftPickups?.pages.flatMap(page => page.data ?? []);
  const activePickups = rawActivePickups?.pages.flatMap(
    page => page.data ?? [],
  );

  const hasDraftPickups = draftPickups && draftPickups.length > 0;
  const hasActivePickups = activePickups && activePickups.length > 0;

  if (isLoadingDraftPickup || isLoadingActivePickup) return <Loading />;

  if (isErrorDraftPickup || isErrorActivePickup) return <Error />;

  if (isInboundDisabled)
    return <Empty message="Saat ini fitur tidak tersedia" />;

  if (!hasActivePickups && !hasDraftPickups)
    return <Empty message="Anda tidak memiliki antrian Jemput Barang" />;

  return (
    <Box px="3">
      <VStack spacing="10" alignItems="stretch">
        {hasActivePickups && (
          <Box>
            <Heading as="h2" size="md" mb="3">
              Sedang Dilakukan
            </Heading>
            <VStack spacing="3" alignItems="stretch">
              {activePickups?.map(order => (
                <PickupItem
                  key={order.id}
                  order={order}
                  onClick={() => edit('pickup', order.id)}
                />
              ))}
            </VStack>

            {hasNextPageActivePickups && (
              <Center my="4">
                <Button
                  variant="link"
                  fontSize="xs"
                  fontWeight="medium"
                  colorScheme="grey"
                  textDecoration="underline"
                  onClick={() => fetchNextPageActivePickups()}
                  disabled={isFetchingNextPageActivePickups}
                >
                  {isFetchingNextPageActivePickups
                    ? 'Sedang menampilkan lainnya...'
                    : 'Tampilkan lainnya'}
                </Button>
              </Center>
            )}
          </Box>
        )}

        {hasDraftPickups && (
          <Box>
            <Heading as="h2" size="md" mb="3">
              Menunggu Penjemputan
            </Heading>
            <VStack spacing="3" alignItems="stretch">
              {draftPickups?.map(order => (
                <PickupItem
                  key={order.id}
                  order={order}
                  onClick={() => edit('pickup', order.id)}
                />
              ))}
            </VStack>

            {hasNextPageDraftPickups && (
              <Center my="4">
                <Button
                  variant="link"
                  fontSize="xs"
                  fontWeight="medium"
                  colorScheme="grey"
                  textDecoration="underline"
                  onClick={() => fetchNextPageDraftPickups()}
                  disabled={isFetchingNextPageDraftPickups}
                >
                  {isFetchingNextPageDraftPickups
                    ? 'Sedang menampilkan lainnya...'
                    : 'Tampilkan lainnya'}
                </Button>
              </Center>
            )}
          </Box>
        )}
      </VStack>
    </Box>
  );
};

type PickupItemProps = {
  order: Order;
  onClick: () => void;
};

const PickupItem = ({ order, onClick }: PickupItemProps) => {
  const { buttonLabel, statusColorScheme, statusLabel } = formatPickupStatus(
    order.status,
  );

  const { label: typeLabel, colorScheme: typeColorScheme } =
    PICKUP_TYPES[order.shipment_type as InboundShipmentType] || {};

  const isStatusPickupToSupplier = ['READY_TO_ORIGIN', 'AT_ORIGIN'].includes(
    order.status,
  );

  return (
    <Card>
      <Box p="3">
        <Flex justifyContent="space-between" alignItems="center">
          <HStack fontSize="x-small" color="brand.500">
            {isStatusPickupToSupplier ? (
              <ArrowBendUpLeft />
            ) : (
              <ArrowBendUpRight />
            )}
            <Text>{isStatusPickupToSupplier ? 'Ke supplier' : 'Ke hub'}</Text>
          </HStack>
          <HStack>
            <Badge colorScheme={typeColorScheme}>{typeLabel}</Badge>
            {order.status !== 'READY_TO_ORIGIN' && (
              <Badge colorScheme={statusColorScheme}>{statusLabel}</Badge>
            )}
          </HStack>
        </Flex>
        <Text fontWeight="semibold" noOfLines={2} flex="1" mb="2">
          {isStatusPickupToSupplier ? order.origin_name : order.dest_name}
        </Text>
        <Text fontSize="xs" color="grey.500" lineHeight="5">
          {composePickupMessage(order)}
        </Text>
      </Box>

      <Box
        as="button"
        py="2"
        px="3"
        w="full"
        color="brand.500"
        borderTop="1px"
        borderTopColor="grey.100"
        bg="brand.50"
        onClick={onClick}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize="sm" fontWeight="medium">
            {buttonLabel}
          </Text>
          <CaretRight size="20" />
        </Flex>
      </Box>
    </Card>
  );
};
