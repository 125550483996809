import { Box } from '@efishery/onefish';
import { flag } from '@onefish/flag';
import { HttpError, useOne, useParsed } from '@refinedev/core';
import { Empty, Loading } from 'components';
import { CONFIG } from 'config';
import { GeoLocationContext } from 'contexts';
import { useFlag } from 'hooks';
import { useCallback } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import type { OrderDetail } from 'types';
import {
  DeliveryDraft,
  DeliveryLoading,
  OnDelivery,
  OnInvoicePayment,
  WaitingForPayment,
} from './components';

export const DeliveryEdit = () => {
  const { id } = useParsed();
  const [searchParams] = useSearchParams();

  const { data, isLoading, isError, refetch } = useOne<OrderDetail, HttpError>({
    resource: 'driver/shipping-order',
    id,
  });

  const order = data?.data;

  const isOn = useFlag(CONFIG.ENABLE_FEAT_HARD_CASH_PAYMENT);
  const isEnableGeoLocation = useFlag(CONFIG.ENABLE_FEAT_GEOLOCATION_FLAG_ID);

  const OnInvoicePaymentWrapped = useCallback(() => {
    return (
      <GeoLocationContext.Provider value={isEnableGeoLocation}>
        <OnInvoicePayment
          deliveryDetail={order}
          refetch={refetch}
          isEnableFeatHardCash={isOn}
        />
      </GeoLocationContext.Provider>
    );
  }, [isEnableGeoLocation, order, refetch, isOn]);

  const OnDeliveryWrapped = useCallback(() => {
    if (order) {
      return (
        <OnDelivery
          deliveryDetail={order}
          isEnableGeoLocation={isEnableGeoLocation}
        />
      );
    }
  }, [isEnableGeoLocation, order]);

  const WaitingForPaymentWrapped = useCallback(() => {
    if (order) {
      return (
        <GeoLocationContext.Provider value={isEnableGeoLocation}>
          <WaitingForPayment deliveryDetail={order} />
        </GeoLocationContext.Provider>
      );
    }
  }, [isEnableGeoLocation, order]);

  const DeliveryLoadingWrapped = useCallback(() => {
    if (order) {
      return (
        <DeliveryLoading
          deliveryDetail={order}
          isEnableGeoLocation={isEnableGeoLocation}
        />
      );
    }
  }, [order, isEnableGeoLocation]);

  if (isLoading) return <Loading />;
  if (isError || !order) return <Empty />;

  let rendered = null;
  const destUrl = `/finish/show/${order.id}`;

  switch (order.status) {
    case 'READY_TO_ORIGIN':
      rendered = <DeliveryDraft deliveryDetail={order} />;
      break;
    case 'AT_ORIGIN':
      rendered = <DeliveryLoadingWrapped />;
      break;
    case 'INTRANSIT':
      rendered = <OnDeliveryWrapped />;
      break;
    case 'PAYMENT':
      rendered = <OnInvoicePaymentWrapped />;
      break;
    case 'WAITING_FOR_PAYMENT':
      if (searchParams.get('change_payment') === 'true') {
        rendered = <OnInvoicePaymentWrapped />;
      } else {
        rendered = <WaitingForPaymentWrapped />;
      }
      break;
    case 'DELIVERY_SUCCESS':
      rendered = (
        <Navigate
          to={destUrl}
          state={{
            finish: true,
          }}
        />
      );
      break;
    default:
      rendered = <Navigate to={`/delivery`} />;
      break;
  }

  return flag(
    CONFIG.ENABLE_DELIVERY,
    <Box px="3">{rendered}</Box>,
    <Empty message="Saat ini fitur tidak tersedia" />,
  );
};
