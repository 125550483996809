import { ShipmentType } from 'types';

export const DELIVERY_PAGE_SIZE = 10;

export const SHIPPING_ORDER_TYPES: {
  [K in ShipmentType]: string;
} = {
  FIRST_MILE: 'Pickup',
  MID_MILE: 'Shuttle',
  LAST_MILE: 'Delivery',
};
