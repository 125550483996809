import React from 'react';
import { createRoot } from 'react-dom/client';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import App from './App';
import { initializeGtm, isEnvTrue } from 'utils';

dayjs.extend(utc);

// add no translate to body
document.documentElement.lang = 'id';
document.documentElement.setAttribute('translate', 'no');
document.body.classList.add('notranslate');
document.body.setAttribute('translate', 'no');

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
if (isEnvTrue(import.meta.env.VITE_ENABLE_GTM)) {
  initializeGtm();
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
