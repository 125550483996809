import { Action, IResourceItem } from '@refinedev/core';

export const customTitleHandler = ({
  resource,
}: {
  resource?: IResourceItem;
  action?: Action;
  params?: Record<string, string | undefined>;
  pathname?: string;
  autoGeneratedTitle: string;
}) => {
  let title = 'eFishery Driver'; // Default title

  if (resource) {
    title = `${resource.label} | ${title}`;
  }

  return title;
};
