import {
  Address,
  Card,
  ComodityDescription,
  Description,
  GeoPopover,
  UploadImage,
} from 'components';
import { FormProvider, useForm } from 'react-hook-form';
import type { OrderDetail } from 'types';

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  InputGroup,
  InputRightAddon,
  NumberInput,
  NumberInputField,
  Text,
  VStack,
} from '@efishery/onefish';
import { ArrowSquareDown } from '@onefish/icons-react';
import type { HttpError } from '@refinedev/core';
import { FormEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAttachmentLink } from 'utils/order';
import type { ToIntransitFormValues } from 'hooks/useDeliveryForm/types';
import { composePickupMessage } from '../helpers';

import { comodity, generateCta } from 'features/transport/utils';
import { useGeoLocation } from 'hooks';
import { geoSavePermission } from 'hooks/useGeoLocation/helper';
import { formatShipDate, getLoadingDefaultValues } from '../../helpers';
import { usePickupLoading } from '../hooks/usePickupLoading';
import { FormOrderLines } from './FormOrderLines';

type PickupLoadingProps = {
  deliveryDetail: OrderDetail;
  'data-testid-prefix'?: string;
  isEnableGeoLocation?: boolean;
};

export const PickupLoading = ({
  deliveryDetail,
  'data-testid-prefix': dataTestIdPrefix = '',
  isEnableGeoLocation,
}: PickupLoadingProps) => {
  const isShuttle = deliveryDetail.shipment_type === 'MID_MILE';

  const { isLoading, updateToNextStatus } = usePickupLoading(deliveryDetail);

  const pageName = 'pickup-loading';
  const {
    isShouldShowPopover,
    onTogglePopover,
    onClosePopover,
    isLocationActive,
    ...geoProps
  } = useGeoLocation({
    positionKey: deliveryDetail.id,
    pageName,
  });

  const methods = useForm<
    ToIntransitFormValues,
    HttpError,
    ToIntransitFormValues
  >({
    defaultValues: getLoadingDefaultValues(deliveryDetail),
  });

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    formState: { errors },
  } = methods;

  const suratJalan = getAttachmentLink(
    deliveryDetail.attachments,
    'CONSIGNMENT_NOTE',
  );

  const watchSuratJalanInput = watch('attachments.0.link');

  const _prependFormWithGeo = () => {
    navigator.geolocation.getCurrentPosition(position => {
      geoSavePermission('granted', pageName);
      register('latitude', { value: position.coords.latitude });
      register('longitude', { value: position.coords.longitude });
      handleSubmit(updateToNextStatus)();
    });
  };

  const submitHandlerWithGeo = (e: FormEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (!isEnableGeoLocation) {
      handleSubmit(updateToNextStatus)();
      return;
    }

    if (isShouldShowPopover()) {
      onTogglePopover();
      return;
    }

    if (document && navigator && isLocationActive()) {
      _prependFormWithGeo();
      return;
    }

    handleSubmit(updateToNextStatus)();
  };

  useEffect(() => {
    if (watchSuratJalanInput) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [watchSuratJalanInput]);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    if (suratJalan) {
      setIsSubmitDisabled(true);
    }
  }, [suratJalan]);

  const confirmationButtonHandler = () => {
    setIsSubmitDisabled(false);
  };

  return (
    <>
      <FormProvider {...methods}>
        <VStack
          w="full"
          alignItems="stretch"
          spacing="3"
          mb="6"
          as="form"
          onSubmit={submitHandlerWithGeo}
        >
          <Card p="4">
            <VStack w="full" alignItems="stretch" spacing="3">
              <Heading as="h2" size="md">
                Muat Barang
              </Heading>

              <Box bg="brand.70" borderRadius="md" p="3" color="brand.600">
                <Box>
                  <Text fontWeight="semibold">
                    {deliveryDetail.origin_name}
                  </Text>
                  <HStack>
                    <ArrowSquareDown weight="fill" />
                    <Text fontSize="sm">Muat barang</Text>
                  </HStack>
                </Box>
              </Box>

              <Text fontSize="md" lineHeight="5" color="grey.500">
                {composePickupMessage(deliveryDetail)}
              </Text>
            </VStack>
          </Card>

          <Address
            address={deliveryDetail.dest_address}
            city={deliveryDetail.dest_city_name}
            province={deliveryDetail.dest_province_name}
          />

          <Card p="4">
            <Description
              name={isShuttle ? 'Tanggal Pengiriman' : 'Tanggal Penjemputan'}
              value={formatShipDate(deliveryDetail.delivery_at)}
            />
          </Card>

          {deliveryDetail.order_lines.map((orderLine, index) => {
            return (
              <Card p="4" key={orderLine.id}>
                <VStack w="full" alignItems="stretch" spacing="2">
                  <ComodityDescription
                    name="Jenis"
                    businessType={deliveryDetail?.business_type}
                    value={orderLine.product_name}
                    data-testid={`${dataTestIdPrefix}txt_product_name-${
                      index + 1
                    }`}
                  />
                  <ComodityDescription
                    name="Ukuran"
                    businessType={deliveryDetail?.business_type}
                    value={orderLine.product_name}
                    data-testid={`${dataTestIdPrefix}txt_product_size-${
                      index + 1
                    }`}
                  />

                  <Divider variant="dashed" />

                  <Description
                    name="Total Tonase yang Akan Dibawa"
                    value={orderLine.quantity}
                    format="thousand-separator"
                    unit="kg"
                    data-testid={`${dataTestIdPrefix}txt_quantity-${index + 1}`}
                  />

                  <Divider variant="dashed" />

                  <FormControl
                    isInvalid={Boolean(
                      errors?.order_lines?.[index]?.carried_quantity,
                    )}
                  >
                    <FormLabel fontSize="sm">
                      {'Total ' +
                        comodity(deliveryDetail?.business_type) +
                        ' Dibawa'}
                    </FormLabel>
                    <InputGroup>
                      <NumberInput w="full" precision={2}>
                        <NumberInputField
                          borderEndRadius="none"
                          {...register(
                            `order_lines.${index}.carried_quantity`,
                            {
                              required: 'Tidak boleh kosong!',
                              valueAsNumber: true,
                              onBlur: () => {
                                trigger(
                                  `order_lines.${index}.carried_quantity`,
                                );
                              },
                            },
                          )}
                        />
                      </NumberInput>
                      <InputRightAddon children="kg" />
                    </InputGroup>
                    <FormErrorMessage>
                      {errors.order_lines?.[index]?.carried_quantity?.message}
                    </FormErrorMessage>
                  </FormControl>
                </VStack>
              </Card>
            );
          })}

          {!isShuttle && (
            <FormOrderLines businessType={deliveryDetail?.business_type} />
          )}

          {suratJalan ? (
            <>
              <Card p="4">
                <Button
                  size={'md'}
                  variant="solid"
                  width={'full'}
                  onClick={confirmationButtonHandler}
                  data-testid={`${dataTestIdPrefix}btn_carried_qty_confirmation`}
                >
                  {'Konfirmasi Total ' +
                    comodity(deliveryDetail?.business_type) +
                    ' Yang Dibawa'}
                </Button>
              </Card>
              <Card p="4">
                <Link
                  hrefLang="id"
                  to={suratJalan}
                  target="_blank"
                  data-testid={`${dataTestIdPrefix}btn_surat_jalan_preview`}
                >
                  <object
                    data={suratJalan}
                    type="application/pdf"
                    width="100%"
                  />
                  <Button size={'md'} variant="link" width={'full'}>
                    Surat Jalan
                  </Button>
                </Link>
              </Card>
            </>
          ) : (
            <Card p="4">
              <FormControl
                isInvalid={Boolean(errors?.attachments?.[0]?.link)}
                isRequired
              >
                <FormLabel fontSize="sm">Surat Jalan</FormLabel>
                <UploadImage
                  title="surat jalan"
                  name="attachments.0.link"
                  fileName="consignment_note"
                  isRequired
                  data-testid-prefix={`${dataTestIdPrefix}surat_jalan-`}
                />
                <FormErrorMessage>
                  {errors?.attachments?.[0]?.link?.message}
                </FormErrorMessage>
              </FormControl>
            </Card>
          )}

          <Button
            width="full"
            type="submit"
            isLoading={isLoading}
            loadingText="Memperbarui status..."
            isDisabled={isSubmitDisabled}
            data-testid={`${dataTestIdPrefix}btn_update_to_next_status`}
          >
            {generateCta(deliveryDetail.status, deliveryDetail.shipment_type)}
          </Button>
        </VStack>
      </FormProvider>
      {isEnableGeoLocation && (
        <GeoPopover
          {...geoProps}
          yesHandler={() => {
            _prependFormWithGeo();
            onClosePopover();
          }}
        />
      )}
    </>
  );
};
