import {
  Card,
  FormControl,
  FormErrorMessage,
  FormLabel,
  VStack,
} from '@chakra-ui/react';
import { ReceivingReport, UploadImage } from 'components';
import { useFormContext } from 'react-hook-form';
import { Attachment } from 'types';
import type { ToSuccessFormValues } from 'hooks/useDeliveryForm/types';

export const UnloadingUploadForm = ({
  receivingReport,
  isGenerateRRLoading,
}: {
  receivingReport?: Attachment;
  isGenerateRRLoading?: boolean;
}) => {
  const { formState, register } = useFormContext<ToSuccessFormValues>();
  const { errors } = formState;

  return (
    <Card p="4">
      <VStack>
        <FormControl isInvalid={Boolean(errors?.attachments?.[0]?.link)}>
          <FormLabel fontSize="sm">Surat Receiving Report (RR)</FormLabel>
          <FormErrorMessage>
            {errors.attachments?.[0]?.link?.message}
          </FormErrorMessage>
          <ReceivingReport
            loading={isGenerateRRLoading}
            link={receivingReport?.link}
          />
          <input
            type="hidden"
            {...register('attachments.0.link', {
              required: 'Tidak boleh kosong!',
            })}
          />
        </FormControl>
        <FormControl isInvalid={Boolean(errors?.attachments?.[1]?.link)}>
          <FormLabel fontSize="sm">Foto Kondisi Produk</FormLabel>
          <UploadImage
            title="kondisi produk"
            name="attachments.1.link"
            fileName="product_condition"
            isRequired
          />
          <FormErrorMessage>
            {errors.attachments?.[1]?.link?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors?.attachments?.[2]?.link)}>
          <FormLabel fontSize="sm">Penerimaan oleh Pembeli</FormLabel>
          <UploadImage
            title="penerimaan oleh pembeli"
            name="attachments.2.link"
            fileName="buyer_acceptance"
            isRequired
          />
          <FormErrorMessage>
            {errors.attachments?.[2]?.link?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors?.attachments?.[3]?.link)}>
          <FormLabel fontSize="sm">Bukti Transfer</FormLabel>
          <UploadImage
            title="bukti transfer"
            name="attachments.3.link"
            fileName="proof_of_paymnent"
          />
          <FormErrorMessage>
            {errors.attachments?.[3]?.link?.message}
          </FormErrorMessage>
        </FormControl>
      </VStack>
    </Card>
  );
};
