import { Box, Divider, Text, VStack } from '@efishery/onefish';
import { Description, isInvoiceExist } from 'components';
import { OrderDetail } from 'types';
import { showFinalPrice, showOrderQuantity } from '../../utils/price';

export const TotalPembayaran = ({
  deliveryDetail,
}: {
  deliveryDetail: OrderDetail;
}) => {
  return (
    <>
      <VStack spacing="4" w="full" alignItems="stretch">
        <Description
          name={`Subtotal (${showOrderQuantity(deliveryDetail)} Pesanan)`}
          value={deliveryDetail.total_final_price_gross}
          format="currency"
        />
        {isInvoiceExist(deliveryDetail.invoice_status) && (
          <Box>
            <Description
              name="Promo"
              value={(deliveryDetail.invoice_total_discount ?? 0) * -1}
              format="currency"
              danger
            />
            {deliveryDetail.promo_code && (
              <Text fontWeight="medium" fontSize="sm">
                {deliveryDetail.promo_code}
              </Text>
            )}
          </Box>
        )}
      </VStack>

      <Divider variant="thick" />

      <VStack spacing="2" w="full" alignItems="stretch">
        <Description
          name="Total Pembayaran"
          value={showFinalPrice(deliveryDetail)}
          important
        />
      </VStack>
    </>
  );
};
