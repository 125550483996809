import * as Sentry from '@sentry/react';
import { CONFIG } from 'config';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { TransportProfileDriverRes } from 'types';

const initialize = () => {
  if (!CONFIG.SENTRY_ENABLED) {
    return;
  }

  Sentry.init({
    dsn: CONFIG.SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 1.0,
    sampleRate: 1,
    release: CONFIG.APP_VERSION,
    environment: CONFIG.STAGE,
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setContext = (context: any, content: any) => {
  Sentry.setContext(context, content);
};

const setUser = (user: TransportProfileDriverRes | null) => {
  Sentry.setUser({
    id: user?.id ?? '',
    username: user
      ? `${user.name.replace(/\s+/g, '_')}-${user.phone_number}`
      : '',
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reportError = (useCase: any, content: any) => {
  Sentry.setContext(
    'manual-error-reporting',
    typeof content === 'object' ? content : { content },
  );
  Sentry.captureException(
    useCase instanceof Error ? useCase : new Error(useCase),
  );
};

export const sentry = { initialize, setUser, setContext, reportError };
