import { currencyIntl } from './number';

export const sendWhatsApp = (phoneNumber: string, text: string) => {
  const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(text)}`;
  window.open(url, '_blank');
};

type GetReceiptMessage = {
  receiptUrl: string;
  orderId: string;
  referenceNumber: string;
  invoiceNumber: string | undefined;
  total: number | undefined;
};

export const getReceiptMessage = ({
  orderId,
  referenceNumber,
  invoiceNumber,
  total,
  receiptUrl,
}: GetReceiptMessage) => {
  return `Halo,

Dengan ini kami ingin menyampaikan bahwa pesananmu sudah selesai. 
Berikut kami lampirkan bukti pembayaran Anda.

Order id: ${orderId}
No.Reference: ${referenceNumber}
No.Invoice: ${invoiceNumber ?? '-'}
Total Pembayaran: ${total ? currencyIntl(total) : '-'}

Bukti pembayaran dapat kamu lihat di ${receiptUrl}

Jika butuh bantuan, silakan balas pesan ini 
atau hubungi eFishery di
No.Tlp: (022) 30500324
WhatsApp: +62 811-8881-8832
Email: cs.support@efishery.com

Terima kasih sudah berbelanja di eFishery!`;
};
