import {
  Divider,
  Flex,
  HStack,
  Heading,
  SimpleGrid,
  Text,
} from '@efishery/onefish';
import { ComodityDescription, Description } from 'components';
import React from 'react';
import type { BusinessType, InvoiceLine } from 'types';
import { currencyIntl } from 'utils';

type InvoiceProps = {
  invoiceLines?: InvoiceLine[];
  promo?: {
    code: string;
    amount: number;
  };
  grandTotal?: number;
  'data-testid-prefix'?: string;
  businessType?: BusinessType;
};

const Invoice = ({
  invoiceLines,
  promo,
  grandTotal,
  'data-testid-prefix': dataTestIdPrefix = '',
  businessType,
}: InvoiceProps) => {
  return (
    <>
      {(invoiceLines ?? [])?.map((line, idx) => {
        return (
          <React.Fragment key={idx}>
            <Heading as="h3" size="xs">
              Produk {idx + 1}
            </Heading>
            <SimpleGrid columns={1} spacing="2">
              <ComodityDescription
                name="Jenis"
                businessType={businessType}
                value={line.product_name}
                data-testid={`${dataTestIdPrefix}txt_invoice_line_product_name-${
                  idx + 1
                }`}
              />
              <ComodityDescription
                name="Ukuran"
                businessType={businessType}
                value={line.description}
                data-testid={`${dataTestIdPrefix}txt_invoice_line_product_description-${
                  idx + 1
                }`}
              />
              <Description
                name="Jumlah yang diterima"
                value={`${line.quantity ?? '-'} (${line.product_uom || '-'})`}
                data-testid={`${dataTestIdPrefix}txt_invoice_line_quantity-${
                  idx + 1
                }`}
              />
              <Description
                name="Harga"
                value={line.price}
                format="intl-currency-idr"
                data-testid={`${dataTestIdPrefix}txt_invoice_line_price-${
                  idx + 1
                }`}
              />
              <Description
                name="Sub Total Product"
                value={line.price_subtotal}
                format="intl-currency-idr"
                data-testid={`${dataTestIdPrefix}txt_invoice_line_subtotal-${
                  idx + 1
                }`}
              />
            </SimpleGrid>
          </React.Fragment>
        );
      })}
      <Divider />
      <SimpleGrid columns={1} spacing="2">
        <Flex flexDir="row" justifyContent="space-between" gap="4">
          <HStack spacing="1">
            <Text fontSize="sm" color="grey.500" fontWeight="normal">
              Promo
            </Text>
            {promo?.code && promo?.code !== '' && (
              <Text
                fontSize="sm"
                borderColor="brand.400"
                borderWidth="0.2em"
                borderRadius="0.5em"
                fontWeight="normal"
                backgroundColor="brand.50"
                padding="0.2em"
                data-testid={`${dataTestIdPrefix}txt_invoice_promo_code`}
              >
                {promo?.code}
              </Text>
            )}
          </HStack>
          <HStack spacing="1">
            <Text
              fontSize="sm"
              textAlign="right"
              fontWeight="normal"
              data-testid={`${dataTestIdPrefix}txt_invoice_promo_amount`}
            >
              {currencyIntl(promo?.amount as number)}
            </Text>
          </HStack>
        </Flex>
        <Description
          name="Total Harga"
          value={grandTotal}
          format="intl-currency-idr"
          data-testid={`${dataTestIdPrefix}txt_invoice_grand_total`}
        />
      </SimpleGrid>
    </>
  );
};

export default Invoice;
