import { OneFishProvider, createStandaloneToast } from '@efishery/onefish';
import { EfisheryProvider } from '@efishery/sdk-reactjs';
import { notificationProvider } from '@refinedev/chakra-ui';
import { Refine } from '@refinedev/core';
import routerBindings, {
  DocumentTitleHandler,
  UnsavedChangesNotifier,
} from '@refinedev/react-router-v6';
import { BrowserRouter } from 'react-router-dom';

import { FlagProvider } from '@onefish/flag';
import Maintenance from 'components/Maintenance';
import { CONFIG } from 'config';
import { fileServiceProvider } from 'features/file/dataProvider';
import { flagServiceProvider } from 'features/flag/dataProvider';
import { tmsServiceProvider } from 'features/transport/dataProvider';
import { authProvider } from 'provider';
import { theme } from 'theme';
import { customTitleHandler, sentry } from 'utils';
import { resources } from './resources';
import { AppRoutes } from './routes';

sentry.initialize();
const { ToastContainer } = createStandaloneToast();

function App() {
  return (
    <BrowserRouter>
      <EfisheryProvider
        clientId={CONFIG.CLIENT_ID}
        stage={CONFIG.STAGE}
        redirectUrl={`${window.location.origin}/redirect`}
        failedRedirectUrl={`${window.location.origin}/redirect`}
      >
        <ToastContainer />
        <OneFishProvider theme={theme}>
          <Refine
            dataProvider={{
              default: tmsServiceProvider,
              fms: fileServiceProvider,
              flagging_service: flagServiceProvider,
            }}
            notificationProvider={notificationProvider}
            routerProvider={routerBindings}
            authProvider={authProvider}
            resources={resources}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
              disableTelemetry: true,
            }}
          >
            <FlagProvider flagUrl={CONFIG.SERVICE_FLAGGING}>
              <Maintenance>
                <AppRoutes />
              </Maintenance>
            </FlagProvider>
            <UnsavedChangesNotifier />
            <DocumentTitleHandler handler={customTitleHandler} />
          </Refine>
        </OneFishProvider>
      </EfisheryProvider>
    </BrowserRouter>
  );
}

export default App;
