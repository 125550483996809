/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseInvalidateProp } from '@refinedev/core';
import { handleAllowedErrors } from 'pages/delivery/helpers';

export const onErrorCallback = (
  error: any,
  invalidate: (props: UseInvalidateProp) => void,
) => {
  handleAllowedErrors(error, () => {
    invalidate({
      resource: `driver/shipping-order`,
      invalidates: ['all'],
    });
  });
};
