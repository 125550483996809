import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Select,
  SimpleGrid,
  Text,
  VStack,
  useDisclosure,
} from '@efishery/onefish';
import {
  BaseRecord,
  HttpError,
  useCreate,
  useInvalidate,
} from '@refinedev/core';
import {
  Card,
  Description,
  Invoice,
  composeInvoiceStatusMsg,
  isAbleToResyncInvoice,
  isShowInvoice,
} from 'components';
import { GeoLocationContext } from 'contexts';
import {
  useFlagData,
  useMetodePembayaran,
  useMutationTimeoutHook,
} from 'hooks';
import { RequestPaymentFormValues } from 'pages/types';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import type { OrderDetail } from 'types';
import { formatDateTimeRange } from 'utils';
import { getFlagAttachment } from 'utils/flagHelper';
import { RequestPaymentForm } from '..';
import { SkipReasonModal } from '../SkipReasonModal';
import { HardCashConfirmationDrawer } from './HardCashConfirmationDrawer';
import { NextStatusButtonHardCash } from './NextStatusButtonHardCash';
import { NextStatusButtonLegacy } from './NextStatusButtonLegacy';
import { CONFIG } from 'config';

type OnInvoicePaymentProps = {
  refetch: () => void;
  deliveryDetail?: OrderDetail;
  isEnableFeatHardCash?: boolean;
};

export const OnInvoicePayment = ({
  deliveryDetail,
  refetch,
  isEnableFeatHardCash,
}: OnInvoicePaymentProps) => {
  const invoiceStatus = deliveryDetail?.invoice_status;

  // timeout using flagr
  const [timeout, setTimeout] = useState<number>(10);
  const flagData = useFlagData(CONFIG.SYNC_INVOICE_TIMEOUT_FLAG_ID);
  useEffect(() => {
    setTimeout(
      Number(getFlagAttachment('timeout', flagData?.variantAttachment, '10')),
    );
  }, [flagData?.variantAttachment]);

  const { retryRequest: retrySyncInvoice, setThreshold: thresholdSyncInvoice } =
    useMutationTimeoutHook(timeout);
  const {
    mutate: syncInvoice,
    isLoading: isLoadingSyncInvoice,
    isError: isSyncError,
  } = useCreate({
    mutationOptions: {
      retry: retrySyncInvoice,
    },
  });

  const syncInvoiceHandler = async () => {
    thresholdSyncInvoice();

    await syncInvoice(
      {
        resource: `/driver/shipping-order/${deliveryDetail?.id}/invoice`,
        values: {},
        successNotification: {
          type: 'success',
          message: 'Berhasil generate Invoice',
        },
      },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
  };
  const syncStatus =
    invoiceStatus === 'CREATE_SUCCESS'
      ? 'success'
      : isSyncError
      ? 'error'
      : undefined;

  const { paymentOpt, metodePembayaranProps } = useMetodePembayaran(
    deliveryDetail?.business_type ?? 'FISH_DOWNSTREAM',
    syncStatus,
    isEnableFeatHardCash,
    deliveryDetail,
  );

  const isEnableGeoLocation = useContext(GeoLocationContext);

  const [isShowPaymentOpts, setIsShowPaymentOpts] = useState(false);

  useEffect(() => {
    setIsShowPaymentOpts(paymentOpt === 'CASH_VA');
  }, [paymentOpt]);

  const {
    isOpen: isOpenSkip,
    onClose: onCloseSkip,
    onOpen: onOpenSkip,
  } = useDisclosure();
  const {
    isOpen: isOpenSend,
    onClose: onCloseSend,
    onOpen: onOpenSend,
  } = useDisclosure();
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const { retryRequest, setThreshold } = useMutationTimeoutHook(timeout);
  const deliverySuccessForm = useForm();
  const invalidate = useInvalidate();

  const [isErrorGeneratePayment, setIsErrorGeneratePayment] = useState(false);

  const whatsAppConfirmationForm = useForm<
    RequestPaymentFormValues,
    HttpError,
    RequestPaymentFormValues
  >({
    defaultValues: { recepient_phone_number: deliveryDetail?.recipient_phone },
  });

  const { mutate: requestVirtualAccount, isLoading: isLoadingVA } = useCreate<
    BaseRecord,
    HttpError,
    RequestPaymentFormValues
  >({
    mutationOptions: {
      retry: retryRequest,
    },
  });

  const VirtualAccountByWhatsAppForm: SubmitHandler<
    RequestPaymentFormValues
  > = async formValues => {
    await setThreshold();
    await requestVirtualAccount(
      {
        resource: `driver/shipping-order/${deliveryDetail?.id}/request-payment-va`,
        values: formValues,
        successNotification: {
          type: 'success',
          message: 'Berhasil mengirimkan WhatsApp',
          description: `Status order ${deliveryDetail?.so_number} menjadi Menunggu Pembayaran`,
        },
        errorNotification: {
          type: 'error',
          message:
            'Payment link gagal dibuat, silahkan lanjutkan dengan metode pembayaran lain.',
        },
      },
      {
        onSuccess: () => {
          invalidate({
            resource: `driver/shipping-order`,
            invalidates: ['all'],
          });
          onCloseSend();
        },
        onError: () => {
          invalidate({
            resource: `driver/shipping-order`,
            invalidates: ['all'],
          });
          onCloseSend();
          setIsErrorGeneratePayment(true);
        },
      },
    );
  };

  const VirtualAccountByDriverAppForm: SubmitHandler<
    RequestPaymentFormValues
  > = async formValues => {
    await setThreshold();
    await requestVirtualAccount(
      {
        resource: `driver/shipping-order/${deliveryDetail?.id}/request-payment-va`,
        values: formValues,
        successNotification: {
          type: 'success',
          message: 'Berhasil mengirimkan WhatsApp',
          description: `Status order ${deliveryDetail?.so_number} menjadi Menunggu Pembayaran`,
        },
        errorNotification: {
          type: 'error',
          message:
            'Payment link gagal dibuat, silahkan lanjutkan dengan metode pembayaran lain.',
        },
      },
      {
        onSuccess: async resp => {
          await invalidate({
            resource: `driver/shipping-order`,
            invalidates: ['all'],
          });
          await onCloseSend();
          await window.open(resp?.data?.payment_url, '_blank');
        },
        onError: () => {
          invalidate({
            resource: `driver/shipping-order`,
            invalidates: ['all'],
          });
          onCloseSend();
          setIsErrorGeneratePayment(true);
        },
      },
    );
  };

  // hard cash drawer
  const {
    isOpen: isOpenHardCash,
    onClose: onCloseHardCash,
    onOpen: onOpenHardCash,
  } = useDisclosure();

  const renderNextStatusButton = useCallback(() => {
    if (deliveryDetail?.business_type === 'SHRIMP_DOWNSTREAM') {
      return (
        <NextStatusButtonLegacy
          isErrorGeneratePayment={isErrorGeneratePayment}
          onOpenSkip={onOpenSkip}
          retrySyncInvoice={retrySyncInvoice}
          paymentOpt={paymentOpt}
          deliveryDetail={deliveryDetail}
          syncStatus={syncStatus}
          isForceEnableSkipPayment
        />
      );
    }

    if (isEnableFeatHardCash) {
      return (
        <NextStatusButtonHardCash
          isErrorGeneratePayment={isErrorGeneratePayment}
          onOpenSkip={onOpenSkip}
          retrySyncInvoice={retrySyncInvoice}
          paymentOpt={paymentOpt}
          deliveryDetail={deliveryDetail}
          syncStatus={syncStatus}
          onOpenHardCash={onOpenHardCash}
        />
      );
    }

    return (
      <NextStatusButtonLegacy
        isErrorGeneratePayment={isErrorGeneratePayment}
        onOpenSkip={onOpenSkip}
        retrySyncInvoice={retrySyncInvoice}
        paymentOpt={paymentOpt}
        deliveryDetail={deliveryDetail}
        syncStatus={syncStatus}
      />
    );
  }, [
    deliveryDetail,
    isEnableFeatHardCash,
    isErrorGeneratePayment,
    onOpenHardCash,
    onOpenSkip,
    paymentOpt,
    retrySyncInvoice,
    syncStatus,
  ]);

  return (
    <>
      <FormProvider {...deliverySuccessForm}>
        <VStack
          w="full"
          alignItems="stretch"
          spacing="3"
          mb="6"
          as="form"
          key="1"
        >
          <Card p="4">
            <VStack w="full" alignItems="stretch" spacing="3">
              <Heading as="h2" size="md">
                Pembayaran
              </Heading>
              <Divider />
              <Heading as="h3" size="sm">
                {deliveryDetail?.dest_name}
              </Heading>
              <Text fontSize="md" lineHeight="5" color="grey.500">
                Sampai di Buyer{' '}
                <em>
                  tanggal{' '}
                  {formatDateTimeRange(
                    deliveryDetail?.expected_delivery_at ?? '',
                    deliveryDetail?.expected_delivery_end_at ?? '',
                  )}
                </em>
              </Text>
              <SimpleGrid columns={1} spacing="2">
                <Description
                  name="Provinsi"
                  value={deliveryDetail?.dest_province_name}
                />
                <Description
                  name="Kota"
                  value={deliveryDetail?.dest_city_name}
                  format="startcase"
                />
                <Description
                  name="Alamat"
                  value={deliveryDetail?.dest_address}
                  format="startcase"
                />
                <Description
                  name="No. HP"
                  value={deliveryDetail?.recipient_phone}
                  format="startcase"
                />
              </SimpleGrid>
            </VStack>
          </Card>

          <Card p="4">
            <VStack w="full" alignItems="stretch" spacing="3">
              <Heading as="h2" size="md">
                {isEnableFeatHardCash ? 'Pembayaran' : 'Rincian Tagihan'}
              </Heading>
              <Divider />
              <SimpleGrid columns={1} spacing="2">
                <Description
                  name="Invoice Number"
                  value={deliveryDetail?.invoice_number}
                  important
                />
                <Description
                  name="Invoice Status"
                  value={composeInvoiceStatusMsg(invoiceStatus)}
                  format="startcase"
                  important
                />
              </SimpleGrid>
              <Button
                size={'md'}
                variant="solid"
                width={'full'}
                data-testid="btn_resync_invoice"
                onClick={syncInvoiceHandler}
                isLoading={isLoadingSyncInvoice}
                isDisabled={!isAbleToResyncInvoice(isSyncError, invoiceStatus)}
              >
                Re-Sync Invoice
              </Button>
              <Divider />
              {isShowInvoice(deliveryDetail?.shipment_type, invoiceStatus) && (
                <Invoice
                  invoiceLines={deliveryDetail?.invoice_lines}
                  promo={{
                    code: deliveryDetail?.promo_code ?? '',
                    amount: deliveryDetail?.invoice_total_discount ?? 0,
                  }}
                  grandTotal={deliveryDetail?.invoice_grand_total_nett}
                  businessType={deliveryDetail?.business_type}
                />
              )}
            </VStack>
          </Card>

          <Card p="4">
            <VStack w="full" alignItems="stretch" spacing="3">
              <Heading as="h2" size="md">
                Metode Pembayaran
              </Heading>

              <Select
                {...metodePembayaranProps}
                id="dd_payment_type"
                placeholder="Pilih metode pembayaran"
                data-testid="dd_payment_type"
              />

              {/* Generate VA Button */}
              {isShowPaymentOpts && (
                <>
                  <Button
                    width="full"
                    loadingText="Mengirim kode pembayaran..."
                    onClick={() => {
                      onOpenSend();
                    }}
                    data-testid="btn_kirim_payment_by_wa"
                  >
                    Kirim Kode Pembayaran via WhatsApp
                  </Button>
                  <Button
                    width="full"
                    loadingText="Membuat kode pembayaran..."
                    backgroundColor={'white'}
                    _hover={{ backgroundColor: 'brand.500', color: 'white' }}
                    color={'brand.400'}
                    borderColor={'brand.400'}
                    border={'2px'}
                    isLoading={isLoadingVA}
                    data-testid="btn_payment_by_app"
                    onClick={() => {
                      VirtualAccountByDriverAppForm({
                        ...whatsAppConfirmationForm.getValues(),
                        send_via: 'DRIVER_APP',
                      });
                    }}
                  >
                    Buat Kode Pembayaran via Driver App
                  </Button>
                </>
              )}
              {/* eof Generate VA Button */}
            </VStack>
          </Card>

          {renderNextStatusButton()}
        </VStack>
      </FormProvider>
      <SkipReasonModal
        isOpen={isOpenSkip}
        deliveryDetail={deliveryDetail}
        retryRequest={retryRequest}
        setThreshold={setThreshold}
        onCancel={() => {
          onCloseSkip();
        }}
        onClose={() => {
          onCloseSkip();
        }}
        isEnableGeoLocation={isEnableGeoLocation}
      />
      {/* WhatsApp */}
      <FormProvider {...whatsAppConfirmationForm}>
        <Drawer
          isOpen={isOpenSend}
          placement="bottom"
          onClose={() => {
            onCloseSend();
            whatsAppConfirmationForm.reset();
          }}
          finalFocusRef={buttonRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <VStack
              w="full"
              maxW="md"
              marginX="auto"
              position="relative"
              align="stretch"
              as="form"
              onSubmit={whatsAppConfirmationForm.handleSubmit(
                VirtualAccountByWhatsAppForm,
              )}
            >
              <DrawerCloseButton />
              <DrawerHeader>Konfirmasi nomor HP Customer</DrawerHeader>

              <DrawerBody>
                <RequestPaymentForm />
              </DrawerBody>

              <DrawerFooter>
                <VStack width="full">
                  <Button
                    width="full"
                    backgroundColor={'white'}
                    _hover={{ backgroundColor: 'brand.500', color: 'white' }}
                    color={'brand.400'}
                    borderColor={'brand.400'}
                    border={'2px'}
                    onClick={() => {
                      onCloseSend();
                    }}
                  >
                    Batal
                  </Button>
                  <Button
                    type="submit"
                    width="full"
                    isLoading={isLoadingVA}
                    data-testid="btn_popup_kirim_payment_link"
                  >
                    Kirim Payment Link
                  </Button>
                </VStack>
              </DrawerFooter>
            </VStack>
          </DrawerContent>
        </Drawer>
      </FormProvider>
      {/* eof WhatsApp */}
      {isEnableFeatHardCash && (
        <HardCashConfirmationDrawer
          isOpen={isOpenHardCash}
          onCloseCallback={onCloseHardCash}
          buttonRef={buttonRef}
          deliveryDetail={deliveryDetail}
          retryRequest={retrySyncInvoice}
        />
      )}
    </>
  );
};
