import {
  Box,
  Divider,
  Heading,
  Image,
  ListItem,
  OrderedList,
  Text,
  VStack,
  Wrap,
} from '@efishery/onefish';
import { HttpError, useOne, useParsed } from '@refinedev/core';
import { Card, Description, Empty } from 'components';
import { PAYMENT_TYPE_LABEL } from 'features/transport/constants';
import { formatDateTimeFull } from 'pages/helpers';
import { OrderDetail } from 'types';
import { getAttachmentLink } from 'utils/order';
import { DetailPengiriman } from './components/DetailPengiriman';
import { PaymentReceiptNavBar } from './components/PaymentReceiptNavBar';
import { TotalPembayaran } from './components/TotalPembayaran';
import { useGetDriverName } from 'hooks';
import { showFinalPrice, showInvoiceNumber } from './utils/price';

export const PaymentReceiptShow = () => {
  const { id } = useParsed();

  const driverName = useGetDriverName();

  const { data } = useOne<OrderDetail, HttpError>({
    resource: '/driver/shipping-order',
    id,
  });

  const deliveryDetail = data?.data;

  if (!deliveryDetail) return <Empty />;

  const hardCashPaymentReceipt = getAttachmentLink(
    deliveryDetail.attachments,
    'PROOF_OF_PAYMENT_HARD_CASH',
  );

  return (
    <>
      <Box px="3" mb="20">
        <Card py="6" px="4">
          <VStack w="full" spacing="4">
            <VStack align="center" spacing="4">
              <Image src="/efishery-logo.png" alt="Logo" h="24px" />
              <VStack align="center" spacing="0">
                <Text color="grey.500">Pembayaran Lunas</Text>
                <Heading>{showFinalPrice(deliveryDetail)}</Heading>
              </VStack>
            </VStack>

            <Divider variant="thickDashed" />

            <Text color="grey.500" fontSize="sm">
              Pelanggan eFishery Yth. <br />
              Terimakasih atas pembayaran yang telah dilakukan dengan informasi
              berikut
            </Text>

            <VStack spacing="2" w="full" alignItems="stretch">
              <Description
                name="Dibayarkan Oleh"
                value={deliveryDetail.dest_name}
              />
              <Description
                name="Tanggal Pembayaran"
                value={formatDateTimeFull(
                  deliveryDetail.payment_received_at ?? '',
                )}
              />
              <Description
                name="Metode Pembayaran"
                value={PAYMENT_TYPE_LABEL[deliveryDetail.payment_type]}
              />
              <Description
                name="No. Referensi"
                value={deliveryDetail.so_number}
              />
              <Description
                name="No. Invoice"
                value={showInvoiceNumber(deliveryDetail)}
              />

              {showInvoiceNumber(deliveryDetail) !== '-' && (
                <Description
                  name=""
                  value={
                    deliveryDetail.so_external_id
                      ? `(${deliveryDetail.so_external_id})`
                      : '-'
                  }
                />
              )}

              <Description name="Diterima Oleh" value={driverName || '-'} />
            </VStack>

            <Divider variant="thickDashed" />

            <DetailPengiriman deliveryDetail={deliveryDetail} />

            <TotalPembayaran deliveryDetail={deliveryDetail} />

            <Box>
              <Text fontSize="sm" color="grey">
                Harap Diperhatikan
              </Text>
              <OrderedList fontSize="sm" fontWeight="medium">
                <ListItem ml="1">
                  Dengan adanya kuitansi ini menyatakan bahwa Anda telah
                  menerima barang atau jasa sesuai dengan jumlah terbilang
                  diatas.
                </ListItem>
                <ListItem ml="1">
                  Kuitansi ini dibuat otomatis secara digital dan sah secara
                  hukum.
                </ListItem>
              </OrderedList>
            </Box>

            <Divider variant="thickDashed" />

            <Wrap align={['left', 'flex-start']} spacing="0">
              <Text fontSize="sm" color="grey">
                PT Efishery Aquaculture Indonesia
              </Text>
              <Text fontSize="sm" color="grey">
                Jl. Malabar No. 37, Kelurahan Samoja, Kecamatan Batununggal,
                Bandung, Jawa Barat, 40273
              </Text>
            </Wrap>

            <Box w="full">
              <Text fontSize="sm" color="grey">
                No.Tlp: (022) 30500324
              </Text>
              <Text fontSize="sm" color="grey">
                WhatsApp: +62 811-8881-8832
              </Text>
              <Text fontSize="sm" color="grey">
                Email: cs.support@efishery.com
              </Text>
            </Box>
          </VStack>
        </Card>
      </Box>
      <PaymentReceiptNavBar
        orderId={deliveryDetail.so_external_id}
        referenceNumber={deliveryDetail.so_number}
        invoiceNumber={deliveryDetail.invoice_number}
        total={deliveryDetail.invoice_grand_total_nett}
        receiptUrl={hardCashPaymentReceipt}
        whatsAppNumber={deliveryDetail.recipient_phone}
      />
    </>
  );
};
