import { Button } from '@efishery/onefish';
import { Link } from 'react-router-dom';
import { FileArrowDown } from '@onefish/icons-react';

type ConsignmentNoteButtonProps = {
  link: string | undefined;
  'data-testid-prefix'?: string;
};

export const ConsignmentNoteButton = ({
  link,
  'data-testid-prefix': dataTestIdPrefix = '',
}: ConsignmentNoteButtonProps) => {
  if (link) {
    return (
      <Link hrefLang="id" to={link} target="_blank">
        <Button
          leftIcon={<FileArrowDown size={18} />}
          size="sm"
          variant="solid"
          width="half"
          colorScheme="purple"
          data-testid={`${dataTestIdPrefix}btn_consignment_note`}
        >
          SJ
        </Button>
      </Link>
    );
  }
  return null;
};
