import { extendTheme } from '@chakra-ui/react';
import { defineStyle, defineStyleConfig } from '@efishery/onefish';

const thick = defineStyle({
  borderColor: '#D2D2D2',
  borderWidth: '2px',
  borderStyle: 'solid',
});

const thickDashed = defineStyle({
  borderColor: '#D2D2D2',
  borderWidth: '2px',
  borderStyle: 'dashed',
});

const dividerTheme = defineStyleConfig({
  variants: { thick, thickDashed },
});

export const theme = extendTheme({
  components: { Divider: dividerTheme },
});
