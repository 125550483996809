import { BusinessType } from 'types';

export const downstreamComodityConditions = (businessType: BusinessType) => {
  if (businessType === 'FISH_DOWNSTREAM') {
    return [
      {
        label: 'Ya, aman',
        value: 'NONE',
        colorScheme: 'brand',
      },
      {
        label: 'Tidak, ada mati',
        value: 'PARTIAL',
        colorScheme: 'yellow',
      },
      {
        label: 'Mati semua',
        value: 'ALL',
        colorScheme: 'red',
      },
    ];
  }

  return [
    {
      label: 'Aman',
      value: 'NONE',
      colorScheme: 'brand',
    },
    {
      label: 'Rusak Sebagian',
      value: 'PARTIAL',
      colorScheme: 'yellow',
    },
    {
      label: 'Rusak Semua',
      value: 'ALL',
      colorScheme: 'red',
    },
  ];
};
