import { Button, Center, Text, VStack } from '@efishery/onefish';
import { WarningOctagon } from '@onefish/icons-react';

type ErrorProps = {
  message?: string;
  action?: () => void;
  actionText?: string;
};

export const Error = ({
  message = 'Tidak dapat menamplilkan data',
  action,
  actionText = 'Coba lagi',
}: ErrorProps) => {
  return (
    <Center color="grey.500" height="full" flex="1">
      <VStack>
        <WarningOctagon size="24" />
        <Text fontSize="sm">{message}</Text>

        {action ? <Button>{actionText}</Button> : null}
      </VStack>
    </Center>
  );
};
