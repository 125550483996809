import { Heading, Switch, SwitchProps } from '@efishery/onefish';
import { Card, Description } from 'components';

type GeoLocationSwitchProps = SwitchProps & {
  locationChangeHandler: () => void;
};

export const GeoLocationSwitch = ({
  locationChangeHandler,
  ...props
}: GeoLocationSwitchProps) => {
  const switchProps = {
    ...props,
    onChange: locationChangeHandler,
  };

  return (
    <Card p="4" ml="auto" mr="auto" w={'75%'}>
      <Heading as="h2" size="md" mb={3} data-testid="txt_pengaturan_driver">
        Pengaturan
      </Heading>
      <Description name="Izinkan Lokasi">
        <Switch {...switchProps} />
      </Description>
    </Card>
  );
};
