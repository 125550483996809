import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { PaymentNextStatusPayload } from 'pages/types';
import { useFormContext } from 'react-hook-form';
import { SKIP_PAYMENT_REMARK_TYPE_LABEL } from '../../../features/transport/constants';

export const SkipPaymentForm = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<PaymentNextStatusPayload>();

  const watchSkipReason = watch('remarks_type');

  return (
    <VStack>
      <FormControl my="2" isInvalid={Boolean(errors?.remarks_type)}>
        <Select
          placeholder="Pilih alasan"
          data-testid="dd_skip_reason"
          {...register('remarks_type', {
            required: 'Alasan lainnya wajib diisi',
          })}
        >
          {Object.entries(SKIP_PAYMENT_REMARK_TYPE_LABEL).map((opt, idx) => {
            return (
              <option key={idx} value={opt[0]}>
                {opt[1]}
              </option>
            );
          })}
        </Select>
        <FormErrorMessage>{errors?.remarks_type?.message}</FormErrorMessage>
      </FormControl>
      {watchSkipReason === 'SKIP_OTHER' && (
        <FormControl my="2" isInvalid={Boolean(errors?.remarks)}>
          <FormLabel fontSize="sm">Keterangan</FormLabel>
          <Textarea
            {...register('remarks', {
              required: 'Alasan lainnya wajib diisi',
            })}
          />
          <FormErrorMessage>{errors?.remarks?.message}</FormErrorMessage>
        </FormControl>
      )}
    </VStack>
  );
};
