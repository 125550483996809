import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Heading,
  StackDivider,
  Text,
  VStack,
} from '@efishery/onefish';
import { DescriptionList, ImagePrompt } from 'components';
import { PAYMENT_TYPE_LABEL } from 'features/transport/constants';
import { showFinalPrice } from 'pages/payment-receipt/utils/price';
import { MutableRefObject, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrderDetail } from 'types';
import { useUpdateToNextStatus } from './useUpdateToNextStatus';
import { GeoLocationContext } from 'contexts';

type HardCashConfirmationDrawerProps = {
  isOpen: boolean;
  onCloseCallback: () => void;
  buttonRef?: MutableRefObject<HTMLButtonElement | null>;
  deliveryDetail?: OrderDetail;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  retryRequest?: (_: any, error: any) => boolean;
};

export const HardCashConfirmationDrawer = ({
  isOpen,
  onCloseCallback,
  buttonRef,
  deliveryDetail,
}: HardCashConfirmationDrawerProps) => {
  const navigate = useNavigate();

  const isEnableGeoLocation = useContext(GeoLocationContext);

  const { updateToNextStatus, isFinishing } = useUpdateToNextStatus({
    deliveryDetail,
    onSuccessCallback: () => {
      onCloseCallback();
      navigate('/paid/' + deliveryDetail?.id, {
        replace: true,
      });
    },
    isEnableGeoLocation,
  });

  const paymentType = deliveryDetail?.payment_type ?? 'HARD_CASH';
  const descItems = [
    {
      testId: 'txt_drawer_description_no_invoice',
      name: 'No Invoice',
      value: deliveryDetail?.invoice_number || '-',
    },
    {
      testId: 'txt_drawer_payment_type',
      name: 'Metode Pembayaran',
      value: PAYMENT_TYPE_LABEL[paymentType] || '-',
    },
  ];

  return (
    <Drawer
      isOpen={isOpen}
      placement="bottom"
      onClose={() => {
        onCloseCallback();
      }}
      finalFocusRef={buttonRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <VStack
          w="full"
          maxW="md"
          marginX="auto"
          position="relative"
          align="stretch"
          as="form"
        >
          <DrawerCloseButton />
          <DrawerHeader>Konfirmasi Pembayaran</DrawerHeader>

          <DrawerBody>
            <ImagePrompt
              imgUrl="/efishery-prompt.png"
              title={<Heading>{showFinalPrice(deliveryDetail)}</Heading>}
              subtitle={
                <Text fontSize="sm" color="grey.500" align={'center'}>
                  Pastikan uang yang diterima sudah sesuai dengan yang
                  ditagihkan.
                </Text>
              }
            />
            <DescriptionList items={descItems} />
          </DrawerBody>
          <StackDivider borderWidth={1} />
          <DrawerFooter>
            <HStack w="full">
              <Button
                width="full"
                backgroundColor={'white'}
                _hover={{ backgroundColor: 'brand.500', color: 'white' }}
                color={'brand.400'}
                borderColor={'brand.400'}
                border={'2px'}
                onClick={() => {
                  onCloseCallback();
                }}
                data-testid="btn_drawer_hardcash_batal"
              >
                Batal
              </Button>
              <Button
                width="full"
                data-testid="btn_drawer_hardcash_konfirmasi"
                onClick={() => {
                  updateToNextStatus();
                }}
                isLoading={isFinishing}
              >
                Konfirmasi
              </Button>
            </HStack>
          </DrawerFooter>
        </VStack>
      </DrawerContent>
    </Drawer>
  );
};
