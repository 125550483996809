import {
  type HttpError,
  useApiUrl,
  useCreate,
  useCustomMutation,
} from '@refinedev/core';
import type { UploadResponse } from 'types';

export const useUploadImage = () => {
  const apiUrl = useApiUrl('fms');

  const {
    mutate: mutateUpload,
    reset: resetUpload,
    status: mutationStatus,
    data,
  } = useCreate<UploadResponse[], HttpError>();

  const { mutate } = useCustomMutation();

  return { apiUrl, mutateUpload, resetUpload, mutationStatus, data, mutate };
};
