import { Stage } from '@efishery/sdk-core';

/* eslint-disable @typescript-eslint/no-explicit-any */
const configValue = (
  key: string,
  type: 'string' | 'number' | 'boolean',
  defaultValue?: any,
) => {
  let value = import.meta.env?.[`VITE_${key}`];
  if (value === undefined) {
    if (defaultValue !== undefined) {
      return defaultValue;
    }
    value = '';
  }
  switch (type) {
    case 'string':
      return value;
    case 'number':
      return Number(value);
    case 'boolean':
      return ['true', '1'].includes(value);
  }
};
export const CONFIG = {
  STAGE:
    Stage[configValue('AUTH_V2_STAGE', 'string', 'stg') as keyof typeof Stage],
  APP_ID: configValue('APP_ID', 'string', 'driver-app'),
  APP_VERSION: configValue('APP_VERSION', 'string', 'preview'),
  CLIENT_ID: configValue('CLIENT_ID', 'string'),
  ENABLE_DELIVERY: configValue('ENABLE_DELIVERY_FLAG_ID', 'number'),
  ENABLE_FEAT_GEOLOCATION_FLAG_ID: configValue(
    'ENABLE_FEAT_GEOLOCATION_FLAG_ID',
    'number',
  ),
  ENABLE_FEAT_HARD_CASH_PAYMENT: configValue(
    'ENABLE_FEAT_HARD_CASH_PAYMENT_FLAG_ID',
    'number',
  ),
  ENABLE_MAINTENANCE_DRIVER_APP: configValue(
    'ENABLE_MAINTENANCE_DRIVER_APP',
    'number',
  ),
  ENABLE_PICKUP: configValue('ENABLE_PICKUP_FLAG_ID', 'number'),
  ENABLE_SHUTTLE: configValue('ENABLE_SHUTTLE_FLAG_ID', 'number'),
  GTM_ID: configValue('GTM_ID', 'string'),
  SERVICE_FILE: configValue('SERVICE_FILE', 'string'),
  SERVICE_FLAGGING: configValue('SERVICE_FLAGGING', 'string'),
  SERVICE_TMS: configValue('SERVICE_TMS', 'string'),
  SENTRY_DSN: configValue('SENTRY_DSN', 'string'),
  SENTRY_ENABLED: configValue('ENABLE_SENTRY', 'boolean'),
  SYNC_INVOICE_TIMEOUT_FLAG_ID: configValue(
    'SYNC_INVOICE_TIMEOUT_FLAG_ID',
    'number',
  ),
  ENABLE_FFMS_EXPLICIT_BUCKET: configValue('ENABLE_FFMS_EXPLICIT_BUCKET', 'boolean'),
  FILE_MANAGER_SERVICE_BUCKET: configValue('FILE_MANAGER_SERVICE_BUCKET', 'string'),
};
