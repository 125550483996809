import { HttpError } from '@refinedev/core';
import { FieldErrors, FormState, useForm } from 'react-hook-form';
import type { OrderDetail } from 'types';
import { getLoadingDefaultValues } from '../../../helpers';
import type { ToIntransitFormValues } from 'hooks/useDeliveryForm/types';

export const getErrors = (formState: FormState<ToIntransitFormValues>) => {
  return formState.errors;
};

export const isSuratJalanInvalid = (
  errors: FieldErrors<ToIntransitFormValues>,
) => {
  return Boolean(errors.attachments?.[0]?.link);
};

export const suratJalanErrMsg = (
  errors: FieldErrors<ToIntransitFormValues>,
) => {
  return errors.attachments?.[0]?.link?.message;
};

export const useDeliveryLoadingForm = (deliveryDetail: OrderDetail) => {
  const methods = useForm<
    ToIntransitFormValues,
    HttpError,
    ToIntransitFormValues
  >({
    defaultValues: getLoadingDefaultValues(deliveryDetail),
  });

  const { register, watch, handleSubmit, trigger } = methods;

  const errors = getErrors(methods.formState);

  const watchSuratJalanInput = watch('attachments.0.link');

  return {
    methods,
    register,
    watch,
    handleSubmit,
    trigger,
    errors,
    watchSuratJalanInput,
  };
};
