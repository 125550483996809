import { useEffect, useState } from 'react';
import { useLogin } from '@refinedev/core';
import { useSearchParams } from 'react-router-dom';

export const Redirect = () => {
  const [searchParams] = useSearchParams();
  const { mutate: login } = useLogin();
  const [, setAuthenticated] = useState(false);

  const clientId = searchParams.get('client_id');
  const grantType = searchParams.get('grant_type');
  const code = searchParams.get('code');

  useEffect(() => {
    async function handleAuthentication() {
      if (!clientId || !grantType || !code) return;

      try {
        login({ grantType, code });
        setAuthenticated(true);
      } finally {
        setAuthenticated(false);
      }
    }

    handleAuthentication();
  }, [clientId, code, grantType, login]);

  return null;
};
