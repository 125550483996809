import React, { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';
import { AppBar as DefaultAppBar } from './AppBar';
import { NavBar as DefaultNavBar } from './NavBar';

type MobileLayoutProps = {
  appBar?: ReactNode;
  navBar?: ReactNode;
  children?: React.ReactNode;
};

export const MobileLayout = ({
  appBar,
  navBar,
  children,
}: MobileLayoutProps) => {
  const renderedAppBar = appBar || appBar === null ? appBar : <DefaultAppBar />;
  const renderedNavBar = navBar || navBar === null ? navBar : <DefaultNavBar />;

  return (
    <Box bg="grey.50">
      <Box
        maxW="md"
        minH="100vh"
        marginX="auto"
        display="flex"
        flexDirection="column"
      >
        {renderedAppBar}
        <Box py="4" flex="1" {...(navBar === null ? { pb: 0 } : {})}>
          {children}
        </Box>
        {renderedNavBar}
      </Box>
    </Box>
  );
};
