import { SimpleGrid } from '@efishery/onefish';

import { Card, Description } from 'components';

type AddressProps = {
  province?: string;
  city?: string;
  address?: string;
};

export const Address = ({ province, city, address }: AddressProps) => {
  return (
    <Card p="4">
      <SimpleGrid columns={1} spacing="2">
        <Description name="Provinsi" value={province} />
        <Description name="Kota" value={city} format="startcase" />
        <Description name="Alamat" value={address} format="startcase" />
      </SimpleGrid>
    </Card>
  );
};
