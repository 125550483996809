import { Button } from '@efishery/onefish';
import { NextStatusButtonProps } from './types';

export const NextStatusButtonHardCash = ({
  paymentOpt,
  onOpenSkip,
  onOpenHardCash,
  deliveryDetail,
}: NextStatusButtonProps) => {
  if (paymentOpt === 'HARD_CASH') {
    return (
      <Button
        width="full"
        loadingText="Memperbarui status..."
        data-testid="btn_finish_payment"
        onClick={() => {
          onOpenHardCash?.();
        }}
      >
        Konfirmasi Pembayaran
      </Button>
    );
  }

  if (deliveryDetail?.payment_type === 'TERMS') {
    return (
      <Button
        width="full"
        loadingText="Memperbarui status..."
        backgroundColor={'yellow.200'}
        _hover={{ backgroundColor: 'yellow.400', color: 'grey.1000' }}
        color={'grey.500'}
        onClick={() => {
          onOpenSkip?.();
        }}
        data-testid="btn_skip_payment"
      >
        Lewati Proses Pembayaran
      </Button>
    );
  }

  return null;
};
