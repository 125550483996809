import { HStack, useRadioGroup } from '@chakra-ui/react';
import { BusinessType } from 'types';
import { downstreamComodityConditions } from 'utils/comodity';
import { RadioCard } from './RadioCard';

type Props = {
  index: number;
  getRadioProps: ReturnType<typeof useRadioGroup>['getRadioProps'];
  businessType?: BusinessType;
};

export const FishConditionRadio = ({
  index,
  getRadioProps,
  businessType,
}: Props) => {
  return (
    <HStack justifyContent="space-between">
      {downstreamComodityConditions(businessType ?? 'FISH_DOWNSTREAM').map(
        condition => {
          const radio = getRadioProps({ value: condition.value });
          return (
            <RadioCard
              key={`${condition.value}-${index}`}
              id={condition.value}
              value={condition.value}
              index={index}
              {...radio}
            >
              {condition.label}
            </RadioCard>
          );
        },
      )}
    </HStack>
  );
};
