import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  useRadioGroup,
} from '@chakra-ui/react';
import { comodity } from 'features/transport/utils';
import type {
  DamageState,
  ToSuccessFormValues,
} from 'hooks/useDeliveryForm/types';
import { useFormContext } from 'react-hook-form';
import type { OrderDetail, OrderLine } from 'types';
import { AdditionalForm } from './AdditionalForm';
import { FishConditionRadio } from './FishConditionRadio';
import { RejectionForm } from './RejectionForm';
import { TotalDamageForm } from './TotalDamageForm';

type Props = {
  deliveryDetail: OrderDetail;
  orderLine: OrderLine;
  index: number;
};

export const FishConditionForm = ({
  deliveryDetail,
  orderLine,
  index,
}: Props) => {
  const { register, formState, setValue, getValues, clearErrors, trigger } =
    useFormContext<ToSuccessFormValues>();

  const { errors } = formState;
  const values = getValues();

  const renderDamageForm = () => {
    if (values.order_lines[index].damage_state === 'NONE')
      return (
        <RejectionForm
          deliveryDetail={deliveryDetail}
          orderLine={orderLine}
          index={index}
        />
      );

    if (values.order_lines[index].damage_state === 'PARTIAL')
      return (
        <>
          <TotalDamageForm
            errors={errors}
            register={register}
            deliveryDetail={deliveryDetail}
            orderLine={orderLine}
            index={index}
            trigger={trigger}
            businessType={deliveryDetail.business_type}
          />
          <AdditionalForm
            errors={errors}
            register={register}
            deliveryDetail={deliveryDetail}
            setValue={setValue}
            index={index}
            trigger={trigger}
          />
        </>
      );

    if (values.order_lines[index].damage_state === 'ALL')
      return (
        <AdditionalForm
          errors={errors}
          register={register}
          deliveryDetail={deliveryDetail}
          setValue={setValue}
          index={index}
          trigger={trigger}
        />
      );

    return null;
  };

  function handleChange(damageState: DamageState) {
    const damagedQuantity: Record<DamageState, number> = {
      ALL: orderLine.carried_quantity,
      PARTIAL: 0, // zero first then let the user decide
      NONE: 0,
    };

    // Resetting every order line value on damage state change
    setValue(`order_lines.${index}`, {
      ...getValues(`order_lines.${index}`),
      receive_good_quantity: orderLine.carried_quantity,
      receive_damaged_quantity: 0,
      final_good_product_price: orderLine.original_price,
      damaged_quantity: damagedQuantity[damageState],
      damage_state: damageState,
    });

    clearErrors(`order_lines.${index}`);
  }

  const { value, getRadioProps } = useRadioGroup({
    onChange: handleChange,
  });

  const isDamagedInvalid = !['NONE', 'PARTIAL', 'ALL'].includes(
    value as string,
  );

  return (
    <>
      <FormControl isInvalid={isDamagedInvalid}>
        <FormLabel>
          {'Apakah ' +
            comodity(deliveryDetail?.business_type) +
            ' ' +
            orderLine.product_name +
            ' diterima dengan kondisi baik semua?'}
        </FormLabel>
        <FishConditionRadio
          index={index}
          getRadioProps={getRadioProps}
          businessType={deliveryDetail.business_type}
        />
        <FormErrorMessage>
          {errors.order_lines?.[index]?.damage_state?.message}
        </FormErrorMessage>
      </FormControl>
      {renderDamageForm()}
    </>
  );
};
