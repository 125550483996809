/**
 * Formats a number as a currency string.
 * 
 * @param number - The number to format.
 * @returns The formatted currency string.
 */
export function currency(number: number) {
  return number.toLocaleString('ID', {
    style: 'currency',
    currency: 'IDR',
    maximumFractionDigits: 0,
  });
}

/**
 * Formats a number by adding thousand separators.
 * @param number - The number to format.
 * @returns The formatted number as a string.
 */
export function thousandSeparator(number: number) {
  return number.toLocaleString('ID');
}

export function currencyIntl(value: number) {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  }).format(value);
}
