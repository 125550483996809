import { UseGetIdentityReturnType } from '@refinedev/core/dist/hooks/auth/useGetIdentity';
import { auth } from 'provider';
import { useCallback } from 'react';
import { trackSignOut } from 'utils';
import { DriverProfile } from './types';

export const useHandleSignOut = (
  profile: UseGetIdentityReturnType<DriverProfile>,
) => {
  const handleSignOut = useCallback(async () => {
    trackSignOut(profile?.data?.phone_number || '');
    await auth.logout();
    window.location.replace('/');
  }, [profile?.data?.phone_number]);
  return handleSignOut;
};
