import type { DataProvider } from '@refinedev/core';
import { CONFIG } from 'config';
import { stringify } from 'query-string';
import { CreateHttp } from 'utils';

const apiUrl = CONFIG.SERVICE_FILE;
const httpClient = CreateHttp(apiUrl);

export const fileServiceProvider: Partial<DataProvider> = {
  create: async ({ resource, variables }) => {
    return await httpClient.post(resource, variables).then(r => {
      return r.data;
    });
  },
  custom: async ({ method, url, payload, headers, query }) => {
    let requestUrl = `${url}?`;

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    let axiosResponse;
    switch (method) {
      case 'put':
      case 'post':
      case 'patch':
        axiosResponse = await httpClient[method](url, payload, { headers });
        break;
      case 'delete':
        axiosResponse = await httpClient.delete(url, {
          data: payload,
          headers,
        });
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl, { headers });
        break;
    }

    const res = axiosResponse;

    return Promise.resolve(res.data);
  },
  getApiUrl: () => {
    return apiUrl;
  },
};
