import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Idle } from './Idle';
import { Loading } from './Loading';
import { Success } from './Success';
import { Error } from './Error';

import { useUploadImage } from './useUploadImage';
import { generateFileName } from 'utils';

import { CONFIG } from 'config';

type UploadImageProps = {
  title: string;
  name: string;
  fileName?: string;
  album?: string;
  bucket?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  'data-testid-prefix'?: string;
};

export const UploadImage = ({
  title,
  name,
  fileName = 'attachment',
  album = 'driver-app',
  bucket = 'public-tools',
  isRequired = false,
  isDisabled = false,
  'data-testid-prefix': dataTestIdPrefix = '',
}: UploadImageProps) => {
  const { apiUrl, mutateUpload, resetUpload, mutationStatus, data, mutate } =
    useUploadImage();
  const inputRef = useRef<HTMLInputElement>(null);

  const { register, setValue, getValues, unregister } = useFormContext();

  register(name, {
    required: isRequired ? 'Tidak boleh kosong!' : false,
  });
  const initialImage = getValues(name);

  const [file, setFile] = useState<File | null>(null);

  const imageUrl =
    (file && URL.createObjectURL(file)) ||
    data?.data[0].location ||
    initialImage;
  const imageKey = data?.data[0].key ?? getKey(imageUrl);

  const resetState = () => {
    unregister(name);
    resetUpload();
  };

  const uploadFile = async (doc: File) => {
    setFile(doc);

    const payload = new FormData();
    payload.append('files', doc);
    payload.append('name', generateFileName(fileName));
    payload.append('album', album);
    if (CONFIG.ENABLE_FFMS_EXPLICIT_BUCKET) {
      payload.append('bucket', CONFIG.FILE_MANAGER_SERVICE_BUCKET);
    }

    mutateUpload(
      {
        dataProviderName: 'fms',
        resource: 'upload',
        values: payload,
        meta: {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      },
      {
        onSuccess: response => {
          const imageUrlResponse = response?.data[0].location;
          if (imageUrlResponse) {
            setValue(name, imageUrlResponse);
          }
        },
      },
    );
  };

  const deleteFile = () => {
    if (!imageKey) return;
    const payload = new URLSearchParams();
    payload.append('key', imageKey);
    payload.append('bucket', bucket);
    mutate(
      {
        dataProviderName: 'fms',
        url: `${apiUrl}/delete`,
        method: 'delete',
        values: payload,
        config: {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        },
      },
      {
        onSuccess: resetState,
      },
    );
  };

  const handleClickUpload = () => {
    inputRef.current?.click();
  };

  const handleChangeFile = ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    resetState();

    const selectedFile = files?.[0];

    if (!(selectedFile instanceof File)) return;
    uploadFile(selectedFile);
  };

  const handleClickCancel = () => {
    resetUpload();
  };

  const handleClickDelete = () => {
    resetUpload();
    deleteFile();
    setValue(name, '');
  };

  const handleClickRetry = () => {
    if (!file) return;
    resetUpload();
    uploadFile(file);
  };

  const status = initialImage ? 'success' : mutationStatus;

  switch (status) {
    case 'idle':
      return (
        <Idle
          ref={inputRef}
          title={title}
          onChangeFile={handleChangeFile}
          onClickUpload={handleClickUpload}
          isDisabled={isDisabled}
          data-testid-prefix={dataTestIdPrefix}
        />
      );

    case 'loading':
      return <Loading onClickCancel={handleClickCancel} />;

    case 'success':
      return (
        <Success
          imageUrl={imageUrl}
          onClickDelete={handleClickDelete}
          isDisabled={isDisabled}
        />
      );

    case 'error':
      return <Error onClickRetry={handleClickRetry} />;
  }
};

const getKey = (url: string, bucket = 'public-tools') => {
  const reUrlPath = /(?:\w+:)?\/\/[^/]+([^?#]+)/;
  const urlParts = url?.match(reUrlPath) || [url, undefined];
  const urlComponent = urlParts?.pop()?.slice(1);
  if (urlComponent) {
    const decoded = decodeURIComponent(urlComponent);
    const regex = new RegExp(`\\b${bucket}/\\b`, 'gi');
    return decoded.replace(regex, '');
  }
};
