export const getFlagAttachment = (
  prop: string,
  attachment?: object,
  defaultValue?: string,
) => {
  if (attachment !== undefined && prop in attachment) {
    return attachment[prop as keyof typeof attachment];
  }

  return defaultValue ?? '';
};
