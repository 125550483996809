import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { Card } from 'components';
import { useFormContext } from 'react-hook-form';
import { CANCEL_REASONS } from '../../../features/transport/constants';
import { CancellationFormValues } from 'pages/types';

export const CancellationForm = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<CancellationFormValues>();

  const watchCancelReason = watch('remarks_type');

  return (
    <Card p="4">
      <VStack>
        <FormControl my="2" isInvalid={Boolean(errors?.remarks_type)}>
          <FormLabel fontSize="sm">Alasan</FormLabel>
          <Select
            placeholder="Pilih alasan"
            {...register('remarks_type', {
              required: 'Alasan wajib dipilih',
            })}
          >
            {CANCEL_REASONS.map(reason => (
              <option key={reason.value} value={reason.value}>
                {reason.label}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors?.remarks_type?.message}</FormErrorMessage>
        </FormControl>

        {watchCancelReason === 'CANCEL_OTHER' && (
          <FormControl my="2" isInvalid={Boolean(errors?.remarks)}>
            <FormLabel fontSize="sm">Keterangan</FormLabel>
            <Textarea
              {...register('remarks', {
                required: 'Alasan lainnya wajib diisi',
              })}
            />
            <FormErrorMessage>{errors?.remarks?.message}</FormErrorMessage>
          </FormControl>
        )}
      </VStack>
    </Card>
  );
};
