import {
  BaseRecord,
  CreateResponse,
  HttpError,
  useCreate,
} from '@refinedev/core';
import type { Attachment, OrderDetail, OrderLinePayload } from 'types';
import { useGetPhoneNumber } from './useGetPhoneNumber';
import { trackReceivingReport } from 'utils';

export const useGenerateRR = (
  deliveryDetail: OrderDetail,
  orderLines: OrderLinePayload[],
  onSuccessCallback: (resp: CreateResponse<BaseRecord & Attachment>) => void,
  onErrorCallback: () => void,
  isAnalytics?: boolean,
) => {
  const { mutate, isLoading } = useCreate<BaseRecord & Attachment, HttpError>();

  const phone = useGetPhoneNumber();

  const generateRR = () => {
    if (isAnalytics) {
      trackReceivingReport('confirm_receiving', {
        phone_number: phone,
      });
    }
    mutate(
      {
        resource: `driver/shipping-order/${deliveryDetail.id}/receiving-report`,
        values: {
          order_lines: orderLines,
        },
        successNotification: {
          type: 'success',
          message: 'Berhasil generate RR',
        },
      },
      {
        onSuccess: onSuccessCallback,
        onError: onErrorCallback,
      },
    );
  };

  return { generateRR, isLoading };
};
