import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  StackDivider,
  VStack,
  useDisclosure,
} from '@efishery/onefish';
import { FileText, WhatsappLogo } from '@onefish/icons-react';
import { useParsed } from '@refinedev/core';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePaymentReceiptAction } from 'hooks';

type ActionNavBarProps = {
  receiptUrl: string | undefined;
  whatsAppNumber: string;
  orderId: string;
  referenceNumber: string;
  invoiceNumber: string | undefined;
  total: number | undefined;
};

export const ActionNavBar = (props: ActionNavBarProps) => {
  const { id } = useParsed();
  const navigate = useNavigate();
  const { onClose: onClose, isOpen: isOpen, onOpen } = useDisclosure();
  const { downloadReceipt, shareViaWhatsApp } = usePaymentReceiptAction(props);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <>
      <VStack
        w="full"
        maxW="md"
        display="flex"
        bg="grey.50"
        gap="3"
        position="fixed"
        bottom="0"
        zIndex="1"
        p={4}
      >
        <Box w="full">
          <Button
            width="full"
            backgroundColor={'white'}
            _hover={{ backgroundColor: 'brand.500', color: 'white' }}
            color={'brand.400'}
            borderColor={'brand.400'}
            border={'2px'}
            data-testid="btn_action_navbar_simpan_bagikan"
            onClick={() => {
              onOpen();
            }}
          >
            Simpan & Bagikan
          </Button>
        </Box>
        <Box w="full">
          <Button
            width="full"
            data-testid="btn_action_navbar_detail_pengiriman"
            onClick={() => {
              navigate(`/finish/show/${id}`, { replace: true });
            }}
          >
            Ke Halaman Detail Pengiriman
          </Button>
        </Box>
      </VStack>
      <Drawer
        isOpen={isOpen}
        placement="bottom"
        onClose={() => {
          onClose();
        }}
        finalFocusRef={buttonRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <VStack
            w="full"
            maxW="md"
            marginX="auto"
            position="relative"
            align="stretch"
            as="form"
          >
            <DrawerCloseButton />
            <DrawerHeader data-testid="txt_simpan_bagikan">
              Simpan & Bagikan
            </DrawerHeader>
            <DrawerBody>
              <VStack
                spacing="4"
                w="full"
                align="start"
                divider={<StackDivider />}
              >
                <Button
                  variant="link"
                  data-testid="btn_drawer_hardcash_save_as_pdf"
                  leftIcon={<FileText size={18} />}
                  colorScheme="black"
                  onClick={downloadReceipt}
                >
                  Simpan sebagai PDF
                </Button>
                <Button
                  variant="link"
                  data-testid="btn_drawer_hardcash_share_via_whatsapp"
                  leftIcon={<WhatsappLogo size={18} />}
                  colorScheme="black"
                  onClick={shareViaWhatsApp}
                >
                  Bagikan via Whatsapp
                </Button>
              </VStack>
            </DrawerBody>
            <StackDivider borderWidth={1} />
            <DrawerFooter>
              <VStack width="full">
                <Button
                  width="full"
                  backgroundColor={'white'}
                  _hover={{ backgroundColor: 'brand.500', color: 'white' }}
                  color={'brand.400'}
                  borderColor={'brand.400'}
                  border={'2px'}
                  onClick={() => {
                    onClose();
                  }}
                  data-testid="btn_drawer_hardcash_kembali"
                >
                  Kembali
                </Button>
              </VStack>
            </DrawerFooter>
          </VStack>
        </DrawerContent>
      </Drawer>
    </>
  );
};
