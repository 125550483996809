import { Box, Button, Text, VStack } from '@efishery/onefish';
import { WarningCircle } from '@onefish/icons-react';

type ErrorProps = {
  onClickRetry: () => void;
};

export const Error = ({ onClickRetry }: ErrorProps) => {
  return (
    <Box
      py="3"
      px="4"
      bg="red.50"
      border="1px"
      borderColor="red.600"
      borderStyle="dashed"
      borderRadius="lg"
    >
      <VStack w="full" spacing="4">
        <Box color="red.600" bg="red.100" borderRadius="full">
          <WarningCircle size="40" weight="fill" />
        </Box>

        <Box fontSize="sm" textAlign="center">
          <Text fontWeight="medium">
            Terjadi kesalahan, foto gagal diupload
          </Text>
          <Text color="grey.500">Pastikan koneksi Anda tersambung</Text>
        </Box>

        <Button width="full" onClick={onClickRetry}>
          Coba lagi
        </Button>
      </VStack>
    </Box>
  );
};
