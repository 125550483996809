import { Button, Heading, VStack, useDisclosure } from '@efishery/onefish';
import type { OrderDetail, PaymentStatus } from 'types';
import {
  Card,
  Description,
  PaymentLink,
  composeInvoiceStatusMsg,
} from 'components';
import { useSearchParams } from 'react-router-dom';
import { PAYMENT_TYPE_LABEL } from '../../../features/transport/constants';
import { useCreate, useInvalidate } from '@refinedev/core';
import { useContext, useState } from 'react';
import { SkipReasonModal } from './SkipReasonModal';
import { useMutationTimeoutHook } from 'hooks';
import { GeoLocationContext } from 'contexts';

type WaitingForPaymentProps = {
  deliveryDetail: OrderDetail;
  'data-testid-prefix'?: string;
};

type CheckPaymentStatusProps = {
  onUnpaid: () => void;
  showSuccess?: boolean;
};

export const WaitingForPayment = ({
  deliveryDetail,
  'data-testid-prefix': dataTestIdPrefix = '',
}: WaitingForPaymentProps) => {
  const invalidate = useInvalidate();
  const [_, setSearchParams] = useSearchParams();
  const [isAllowedToSkipPayment, setIsAllowedToSkipPayment] = useState(false);
  const { mutate, isLoading } = useCreate<{ status: PaymentStatus }>();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { retryRequest, setThreshold } = useMutationTimeoutHook();

  const checkPaymentStatus = ({
    onUnpaid,
    showSuccess = true,
  }: CheckPaymentStatusProps) => {
    mutate(
      {
        resource: `driver/shipping-order/${deliveryDetail?.id}/payment-status`,
        values: {},
        successNotification: showSuccess
          ? {
              type: 'success',
              message: 'Berhasil update status pembayaran',
            }
          : false,
      },
      {
        onSuccess: data => {
          if (data.data.status === 'PAID') {
            invalidate({
              resource: `driver/shipping-order`,
              id: deliveryDetail?.id,
              invalidates: ['detail'],
            });
          } else {
            onUnpaid();
          }
        },
        onError: () => {
          onUnpaid();
        },
      },
    );
  };

  const isEnableGeoLocation = useContext(GeoLocationContext);

  return (
    <>
      <VStack w="full" alignItems="stretch" spacing="3" mb="6">
        <Card p="4">
          <Heading as="h2" size="md">
            Menunggu Pembayaran
          </Heading>
        </Card>

        <Card p="4">
          <VStack w="full" alignItems="stretch" spacing="2">
            <Description
              name="Nomor Invoice"
              value={deliveryDetail.invoice_number}
            />
            <Description
              name="Nilai Pembayaran"
              value={deliveryDetail.invoice_grand_total_nett}
              format="intl-currency-idr"
            />
            <Description
              name="Metode Pembayaran"
              value={PAYMENT_TYPE_LABEL[deliveryDetail.payment_type]}
            />
            <Description
              name="Status Pembayaran"
              value={
                deliveryDetail.invoice_status
                  ? composeInvoiceStatusMsg(deliveryDetail.invoice_status)
                  : '-'
              }
            />
            <PaymentLink
              link={deliveryDetail?.payment_va_link}
              data-testid-prefix={dataTestIdPrefix}
            />
          </VStack>
        </Card>

        <Button
          width="full"
          type="submit"
          loadingText="Memperbarui Status Pembayaran..."
          isLoading={isLoading}
          onClick={() =>
            checkPaymentStatus({
              onUnpaid: () => {
                setIsAllowedToSkipPayment(true);
              },
            })
          }
        >
          Update Status Pembayaran
        </Button>
        <VStack spacing="4">
          <Button
            width="full"
            variant="outline"
            colorScheme="red"
            loadingText="Melewati Proses Menunggu Pembayaran..."
            isDisabled={!isAllowedToSkipPayment}
            onClick={() => {
              onOpen();
            }}
          >
            Lewati Proses Menunggu Pembayaran
          </Button>
          <Button
            width="full"
            variant="outline"
            colorScheme="yellow"
            loadingText="Memeriksa Status Pembayaran..."
            isLoading={isLoading}
            onClick={() => {
              checkPaymentStatus({
                showSuccess: false,
                onUnpaid: () => {
                  setSearchParams(currentParams => {
                    currentParams.set('change_payment', 'true');
                    return currentParams;
                  });
                },
              });
            }}
          >
            Ubah Metode Pembayaran
          </Button>
        </VStack>
      </VStack>
      <SkipReasonModal
        isOpen={isOpen}
        deliveryDetail={deliveryDetail}
        retryRequest={retryRequest}
        setThreshold={setThreshold}
        onCancel={() => {
          onClose();
        }}
        onClose={() => {
          onClose();
        }}
        isEnableGeoLocation={isEnableGeoLocation}
      />
    </>
  );
};
