import { Box, HStack, Text } from '@efishery/onefish';
import { ArrowLeft } from '@onefish/icons-react';
import { useNavigation, useResource } from '@refinedev/core';
import { useLocation } from 'react-router-dom';

type AppBarProps = {
  title?: string;
};

export const AppBar = ({ title }: AppBarProps) => {
  const location = useLocation();
  const { resource } = useResource();
  const { goBack, push } = useNavigation();

  const handleBack = () => {
    if (location.state?.finish) {
      push('/finish');
    } else {
      goBack();
    }
  };

  const titleToRender =
    title ?? resource?.meta?.headerTitle ?? resource?.meta?.label;

  return (
    <Box
      py="2"
      px="4"
      w="full"
      height="56px"
      display="flex"
      alignItems="center"
      bg="white"
      position="sticky"
      top="0"
      zIndex="1"
      shadow="sm"
    >
      <Box w="full">
        <HStack spacing="4">
          <Box
            as="button"
            borderRadius="sm"
            _hover={{ bg: 'gray.50' }}
            _active={{ color: 'gray.500', transform: 'scale(0.9)' }}
            onClick={handleBack}
            data-testid="btn_back"
          >
            <ArrowLeft size="24" />
          </Box>

          <Text fontSize="2xl" fontWeight="semibold">
            {titleToRender}
          </Text>
        </HStack>
      </Box>
    </Box>
  );
};
