import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Text,
  VStack,
} from '@efishery/onefish';
import { MapPinLine } from '@onefish/icons-react';
import {
  BaseRecord,
  HttpError,
  useCreate,
  useInvalidate,
} from '@refinedev/core';
import {
  Address,
  Card,
  ComodityDescription,
  ConsignmentNoteButton,
  Description,
} from 'components';
import { generateCta } from 'features/transport/utils';
import React from 'react';
import type { OrderDetail } from 'types';
import { getAttachmentLink } from 'utils/order';
import { formatShipDate } from '../../helpers';
import { composeDeliveryMessage } from '../helpers';

type DeliveryDraftProps = {
  deliveryDetail: OrderDetail;
};

export const DeliveryDraft = ({ deliveryDetail }: DeliveryDraftProps) => {
  const { mutate, isLoading } = useCreate<BaseRecord, HttpError>();
  const invalidate = useInvalidate();

  const updateToNextStatus = () => {
    mutate(
      {
        resource: `driver/shipping-order/${deliveryDetail.id}/status/at-origin`,
        values: {},
        successNotification: {
          type: 'success',
          message: 'Berhasil update status order',
          description: `Status order ${deliveryDetail.so_number} menjadi Muat Barang`,
        },
      },
      {
        onSuccess: () => {
          invalidate({
            resource: `driver/shipping-order`,
            invalidates: ['all'],
          });
        },
      },
    );
  };

  const consignmentNote = getAttachmentLink(
    deliveryDetail.attachments,
    'CONSIGNMENT_NOTE',
  );

  return (
    <VStack w="full" alignItems="stretch" spacing="3" mb="6">
      <Card p="4">
        <VStack w="full" alignItems="stretch" spacing="3">
          <Heading as="h2" size="md">
            Menunggu Penjemputan
          </Heading>

          <Flex bg="brand.70" borderRadius="md" p="3" color="brand.600">
            <Box w={consignmentNote ? '80%' : '100%'}>
              <Text fontWeight="semibold">{deliveryDetail.origin_name}</Text>
              <HStack>
                <MapPinLine />
                <Text fontSize="sm">Sampai di lokasi penjemputan</Text>
              </HStack>
            </Box>
            <ConsignmentNoteButton link={consignmentNote} />
          </Flex>

          <Text fontSize="md" lineHeight="5" color="grey.500">
            {composeDeliveryMessage(deliveryDetail)}
          </Text>
        </VStack>
      </Card>

      <Address
        address={deliveryDetail.dest_address}
        city={deliveryDetail.dest_city_name}
        province={deliveryDetail.dest_province_name}
      />

      <Card p="4">
        <Description
          name="Tanggal Penjemputan"
          value={formatShipDate(deliveryDetail.delivery_at)}
        />
      </Card>

      <Card p="4">
        <VStack w="full" alignItems="stretch" spacing="2">
          {deliveryDetail.order_lines.map((orderLine, index) => {
            return (
              <React.Fragment key={orderLine.id}>
                <ComodityDescription
                  name="Jenis"
                  businessType={deliveryDetail?.business_type}
                  value={orderLine.product_name}
                />
                <ComodityDescription
                  name="Ukuran"
                  businessType={deliveryDetail?.business_type}
                  value={orderLine.product_name}
                />

                <Description
                  name="Total Tonase yang Akan Dibawa"
                  value={orderLine.quantity}
                  format="thousand-separator"
                  unit="kg"
                />

                <Description
                  name="Harga"
                  value={new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 2,
                  }).format(orderLine.original_price)}
                />
                {index !== deliveryDetail.order_lines.length - 1 && (
                  <Divider variant="dashed" />
                )}
              </React.Fragment>
            );
          })}

          <Divider borderColor="brand.500" />

          <Description
            name="Total"
            value={new Intl.NumberFormat('id-ID', {
              style: 'currency',
              currency: 'IDR',
              minimumFractionDigits: 2,
            }).format(deliveryDetail.total_original_price)}
          />
        </VStack>
      </Card>

      <Button
        width="full"
        onClick={updateToNextStatus}
        isLoading={isLoading}
        loadingText="Memperbarui status..."
        data-testid="btn_update_status"
      >
        {generateCta(deliveryDetail.status)}
      </Button>
    </VStack>
  );
};
