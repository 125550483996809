import {
  Flex,
  FlexboxProps,
  HStack,
  Text,
  TypographyProps,
} from '@efishery/onefish';
import {
  capitalize,
  currency,
  currencyIntl,
  startCase,
  thousandSeparator,
  toKebabCase,
} from 'utils';

type Direction = 'horizontal' | 'vertical';

export type Format =
  | 'startcase'
  | 'capitalize'
  | 'currency'
  | 'thousand-separator'
  | 'intl-currency-idr';

type DescriptionProps = {
  name: string;
  value?: string | number | null;
  direction?: Direction;
  important?: boolean;
  format?: Format;
  unit?: string;
  children?: React.ReactNode;
  danger?: boolean;
  'data-testid'?: string;
};

const flexProps: {
  [k in Direction]: FlexboxProps;
} = {
  horizontal: { flexDir: 'row', justifyContent: 'space-between', gap: '4' },
  vertical: { flexDir: 'column', justifyContent: 'flex-start' },
};

const textAlign: {
  [k in Direction]: TypographyProps['textAlign'];
} = {
  horizontal: 'right',
  vertical: 'left',
};

function formattedValue(args: Pick<DescriptionProps, 'value' | 'format'>) {
  const { value, format } = args;

  if (!value) return format === 'intl-currency-idr' ? 'Rp 0,00' : '-';

  switch (format) {
    case 'capitalize':
      return capitalize(value?.toString());
    case 'startcase':
      return startCase(value?.toString());
    case 'currency':
      return currency(Number(value));
    case 'thousand-separator':
      return thousandSeparator(Number(value));
    case 'intl-currency-idr':
      return currencyIntl(value as number);
    default:
      return value;
  }
}

export const Description = ({
  name,
  value,
  direction = 'horizontal',
  important = false,
  format,
  unit,
  children,
  danger = false,
  'data-testid': dataTestId = '',
}: DescriptionProps) => {
  return (
    <Flex {...flexProps[direction]}>
      <Text
        fontSize="sm"
        color="grey.500"
        fontWeight={important ? 'semibold' : 'normal'}
      >
        {name}
      </Text>
      {children ?? (
        <HStack spacing="1">
          <Text
            fontSize="sm"
            textAlign={textAlign[direction]}
            fontWeight={important || danger ? 'semibold' : 'normal'}
            color={danger ? 'red' : ''}
            data-testid={dataTestId || `txt_description_${toKebabCase(name)}`}
          >
            {formattedValue({ value, format })}
          </Text>
          {Boolean(unit) && (
            <Text as="span" fontSize="sm">
              {unit}
            </Text>
          )}
        </HStack>
      )}
    </Flex>
  );
};
