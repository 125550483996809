import { Center, Text, VStack } from '@efishery/onefish';
import { Info } from '@onefish/icons-react';

type EmptyProps = {
  message?: string;
};

export const Empty = ({
  message = 'Data yang Anda cari tidak ditemukan',
}: EmptyProps) => {
  return (
    <Center color="grey.500" height="full" flex="1">
      <VStack>
        <Info size="24" />
        <Text fontSize="sm">{message}</Text>
      </VStack>
    </Center>
  );
};
