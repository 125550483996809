import { Box, Button, Spinner, Text, VStack } from '@efishery/onefish';
import { X } from '@onefish/icons-react';

type LoadingProps = {
  onClickCancel: () => void;
};

export const Loading = ({ onClickCancel }: LoadingProps) => {
  return (
    <Box
      py="3"
      px="4"
      border="1px"
      borderColor="grey.200"
      borderStyle="dashed"
      borderRadius="lg"
    >
      <VStack w="full" spacing="4">
        <Box>
          <Spinner color="brand.500" size="xl" speed="0.65s" />
        </Box>

        <Box fontSize="sm" textAlign="center">
          <Text fontWeight="medium">Sedang mengupload</Text>
          <Text color="grey.500">Pastikan koneksi tetap tersambung</Text>
        </Box>

        <Button
          colorScheme="red"
          variant="outline"
          width="full"
          leftIcon={<X size="18" />}
          onClick={onClickCancel}
        >
          Batalkan
        </Button>
      </VStack>
    </Box>
  );
};
