import {
  type BaseRecord,
  type HttpError,
  useCreate,
  useInvalidate,
} from '@refinedev/core';
import type { ToIntransitFormValues } from 'hooks/useDeliveryForm/types';
import type { SubmitHandler } from 'react-hook-form';
import type { OrderDetail } from 'types';

export const usePickupLoading = (deliveryDetail: OrderDetail) => {
  const { mutate, isLoading } = useCreate<
    BaseRecord,
    HttpError,
    ToIntransitFormValues
  >();

  const invalidate = useInvalidate();

  const updateToNextStatus: SubmitHandler<
    ToIntransitFormValues
  > = formValues => {
    mutate(
      {
        resource: `driver/shipping-order/${deliveryDetail.id}/status/intransit`,
        values: formValues,
        successNotification: {
          type: 'success',
          message: 'Berhasil update status order',
          description: `Status order ${deliveryDetail.so_number} menjadi Lanjut Kirim Barang`,
        },
      },
      {
        onSuccess: () => {
          invalidate({
            resource: `driver/shipping-order`,
            invalidates: ['all'],
          });
        },
      },
    );
  };

  return {
    isLoading,
    updateToNextStatus,
  };
};
