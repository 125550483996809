import { Button } from '@efishery/onefish';
import type { PaymentType } from 'types';
import { NextStatusButtonProps, SyncStatus } from './types';
import { useUpdateToNextStatus } from './useUpdateToNextStatus';
import { useCallback, useContext } from 'react';
import { GeoLocationContext } from 'contexts';

const isAbleToFinishPayment = (
  syncStatus?: SyncStatus,
  paymentType?: PaymentType,
) => {
  if (syncStatus === 'success' && paymentType === 'HARD_CASH') {
    return true;
  }
  return false;
};

const isAbleToSkipPayment = (
  syncStatus?: SyncStatus,
  paymentType?: PaymentType,
  isErrorGeneratePayment?: boolean,
) => {
  if (syncStatus === 'error') {
    return true;
  }
  if (syncStatus === 'success' && paymentType === 'TERMS') {
    return true;
  }

  if (
    syncStatus === 'success' &&
    paymentType !== 'TERMS' &&
    isErrorGeneratePayment
  ) {
    return true;
  }

  return false;
};

export const NextStatusButtonLegacy = ({
  deliveryDetail,
  isErrorGeneratePayment,
  onOpenSkip,
  paymentOpt,
  retrySyncInvoice: retryRequest,
  syncStatus,
  isForceEnableSkipPayment,
}: NextStatusButtonProps) => {
  const isEnableGeoLocation = useContext(GeoLocationContext);

  const { updateToNextStatus, isFinishing } = useUpdateToNextStatus({
    deliveryDetail,
    retryRequest,
    isEnableGeoLocation,
  });

  const isEnableSkipPayment = useCallback(() => {
    if (isForceEnableSkipPayment) {
      return true;
    }

    return isAbleToSkipPayment(
      syncStatus,
      deliveryDetail?.payment_type,
      isErrorGeneratePayment,
    );
  }, [
    deliveryDetail?.payment_type,
    isErrorGeneratePayment,
    isForceEnableSkipPayment,
    syncStatus,
  ]);

  return isAbleToFinishPayment(syncStatus, paymentOpt) ? (
    <Button
      type="submit"
      width="full"
      loadingText="Memperbarui status..."
      onClick={() => {
        updateToNextStatus();
      }}
      data-testid="btn_finish_payment"
      isLoading={isFinishing}
    >
      Selesaikan Pengiriman
    </Button>
  ) : (
    <Button
      width="full"
      loadingText="Memperbarui status..."
      backgroundColor={'yellow.200'}
      _hover={{ backgroundColor: 'yellow.400', color: 'grey.1000' }}
      color={'grey.500'}
      isDisabled={!isEnableSkipPayment()}
      onClick={() => {
        onOpenSkip?.();
      }}
      data-testid="btn_skip_payment"
    >
      Lewati Proses Pembayaran
    </Button>
  );
};
