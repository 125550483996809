import { Button, Heading, VStack } from '@efishery/onefish';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Card } from 'components';
import { comodity } from 'features/transport/utils';
import type { ToIntransitFormValues } from 'hooks/useDeliveryForm/types';
import { BusinessType } from 'types';
import { InputFields } from './InputFields';

export const FormOrderLines = ({
  businessType = 'FISH_DOWNSTREAM',
}: {
  businessType?: BusinessType;
}) => {
  const { control } = useFormContext<ToIntransitFormValues>();
  const businessTypeQuery = businessType;

  const { fields, append, remove } = useFieldArray({
    name: 'additional_order_lines',
    control,
  });

  return (
    <Card p="4">
      <VStack w="full" alignItems="stretch" spacing="3">
        <Heading as="h3" size="sm">
          Bawa {comodity(businessType)} ukuran lain untuk dikirim ke HUB ini?
        </Heading>

        {fields.map((field, index) => (
          <InputFields
            key={field.id}
            index={index}
            onRemove={() => remove(index)}
            businessTypeQuery={businessTypeQuery}
          />
        ))}

        <Button
          variant="outline"
          width="full"
          onClick={() =>
            append({
              carried_quantity: 0,
              product_name: '',
              product_code: '',
              product_uom: '',
              weight_in_kg: 0,
            })
          }
        >
          {'Tambah Jenis ' + comodity(businessType) + ' Lainnya'}
        </Button>
      </VStack>
    </Card>
  );
};
