import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Text,
  VStack,
} from '@efishery/onefish';
import { MapPinLine } from '@onefish/icons-react';
import {
  Address,
  Card,
  ComodityDescription,
  ConsignmentNoteButton,
  Description,
} from 'components';
import { generateCta } from 'features/transport/utils';
import React from 'react';
import type { OrderDetail } from 'types';
import { getAttachmentLink } from 'utils/order';
import { formatShipDate } from '../../helpers';
import { composePickupMessage } from '../helpers';
import { usePickupDraft } from '../hooks/usePickupDraft';

type PickupDraftProps = {
  deliveryDetail: OrderDetail;
  'data-testid-prefix'?: string;
};

export const PickupDraft = ({
  deliveryDetail,
  'data-testid-prefix': dataTestIdPrefix = '',
}: PickupDraftProps) => {
  const isShuttle = deliveryDetail?.shipment_type === 'MID_MILE';

  const { isLoading, updateToNextStatus } = usePickupDraft(deliveryDetail);

  const consignmentNote = getAttachmentLink(
    deliveryDetail.attachments,
    'CONSIGNMENT_NOTE',
  );

  return (
    <VStack w="full" alignItems="stretch" spacing="3" mb="6">
      <Card p="4">
        <VStack w="full" alignItems="stretch" spacing="3">
          <Heading as="h2" size="md">
            Menunggu Penjemputan
          </Heading>

          <Flex bg="brand.70" borderRadius="md" p="3" color="brand.600">
            <Box w={consignmentNote ? '80%' : '100%'}>
              <Text fontWeight="semibold">{deliveryDetail.origin_name}</Text>
              <HStack>
                <MapPinLine />
                <Text fontSize="sm">Sampai di lokasi penjemputan</Text>
              </HStack>
            </Box>
            <ConsignmentNoteButton link={consignmentNote} />
          </Flex>

          <Text fontSize="md" lineHeight="5" color="grey.500">
            {composePickupMessage(deliveryDetail)}
          </Text>
        </VStack>
      </Card>

      <Address
        address={deliveryDetail.dest_address}
        city={deliveryDetail.dest_city_name}
        province={deliveryDetail.dest_province_name}
      />

      <Card p="4">
        <Description name="Status Penjemputan" value="-" />
      </Card>

      <Card p="4">
        <Description
          name={isShuttle ? 'Tanggal Pengiriman' : 'Tanggal Penjemputan'}
          value={formatShipDate(deliveryDetail.delivery_at)}
        />
      </Card>

      <Card p="4">
        <VStack w="full" alignItems="stretch" spacing="2">
          {deliveryDetail.order_lines.map((orderLine, index) => {
            return (
              <React.Fragment key={orderLine.id}>
                <ComodityDescription
                  name="Jenis"
                  businessType={deliveryDetail?.business_type}
                  value={orderLine.product_name}
                  data-testid={`${dataTestIdPrefix}txt_product_name-${
                    index + 1
                  }`}
                />
                <ComodityDescription
                  name="Ukuran"
                  businessType={deliveryDetail?.business_type}
                  value={orderLine.product_name}
                  data-testid={`${dataTestIdPrefix}txt_product_size-${
                    index + 1
                  }`}
                />

                <Description
                  name="Total Tonase yang Akan Dibawa"
                  value={orderLine.quantity}
                  format="thousand-separator"
                  unit="kg"
                  data-testid={`${dataTestIdPrefix}txt_quantity-${index + 1}`}
                />

                {index !== deliveryDetail.order_lines.length - 1 && (
                  <Divider variant="dashed" />
                )}
              </React.Fragment>
            );
          })}
        </VStack>
      </Card>

      <Button
        width="full"
        onClick={updateToNextStatus}
        isLoading={isLoading}
        loadingText="Memperbarui status..."
        data-testid={`${dataTestIdPrefix}btn_update_to_next_status`}
      >
        {generateCta(deliveryDetail.status, deliveryDetail.shipment_type)}
      </Button>
    </VStack>
  );
};
