import { DEFAULT_REJECTED_REASON_TYPE } from 'features/transport/constants';
import { geoFetchLocationFromStorage } from 'hooks/useGeoLocation/helper';
import { OrderDetail } from 'types';
import {
  DamageState,
  OrderLine,
  PageType,
  ToIntransitFormValues,
  ToSuccessFormValues,
  ToSuccessTransformedFormValues,
} from './types';

const getPickupOnDeliveryDefaultValues = (
  deliveryDetail: OrderDetail,
): ToIntransitFormValues => {
  return {
    attachments: [{ link: '', type: 'RECEIVING_REPORT' }],
    order_lines: deliveryDetail.order_lines.map(orderLine => ({
      id: orderLine.id,
      receive_good_quantity: undefined,
      receive_damaged_quantity: undefined,
      final_good_product_price: orderLine.original_price,
      final_damaged_product_price: 0,
      rejected_quantity: 0,
      rejected_reason_detail: '',
      rejected_reason_type: '',
      damaged_quantity: 0,
      carried_quantity: orderLine.carried_quantity,
      damage_state: undefined,
    })),
  };
};

const getOnDeliveryDefaultValues = (
  deliveryDetail: OrderDetail,
): ToSuccessFormValues => {
  return {
    order_lines: deliveryDetail.order_lines.map(orderLine => {
      return {
        id: orderLine.id,
        receive_good_quantity: orderLine.carried_quantity,
        receive_damaged_quantity: 0,
        final_good_product_price: orderLine.original_price,
        final_damaged_product_price: orderLine.original_price / 2 + 5000,
        damaged_quantity: 0,
        carried_quantity: orderLine.carried_quantity,
        damage_state: undefined,
        rejected_quantity: 0,
        rejected_reason_detail: '',
        rejected_reason_type: '',
      };
    }),
    attachments: [
      {
        link: '',
        type: 'RECEIVING_REPORT',
      },
      {
        link: '',
        type: 'PRODUCT_CONDITION',
      },
      {
        link: '',
        type: 'BUYER_ACCEPTANCE',
      },
      {
        link: '',
        type: 'PROOF_OF_PAYMENT',
      },
    ],
  };
};

export const getOnDeliveryDefaultValuesProxy = (
  pageType: PageType,
  deliveryDetail: OrderDetail,
) => {
  switch (pageType) {
    case 'DELIVERY':
      return getOnDeliveryDefaultValues(deliveryDetail);
    case 'PICKUP':
      return getPickupOnDeliveryDefaultValues(deliveryDetail);
  }
};

export const generateRejectedReasonType = (orderLine: OrderLine) => {
  return orderLine.damage_state !== 'NONE'
    ? DEFAULT_REJECTED_REASON_TYPE[
        orderLine.damage_state as Exclude<DamageState, 'NONE'>
      ]
    : orderLine.rejected_reason_type;
};

export const transformSuccessPayloadDelivery = (
  formValues: ToSuccessFormValues,
  deliveryId?: string,
  isEnableGeoLocation?: boolean,
): ToSuccessTransformedFormValues => {
  const attachments = formValues.attachments?.filter(
    attachment => attachment.link,
  );
  const orderLines = formValues.order_lines.map(orderLine => {
    const rejectedQty =
      orderLine.damage_state !== 'NONE'
        ? orderLine.damaged_quantity - orderLine.receive_damaged_quantity
        : orderLine.rejected_quantity;

    const rejectedReasonType = generateRejectedReasonType(orderLine);

    const receivedGoodQtySubtractor =
      orderLine.damage_state !== 'NONE'
        ? orderLine.damaged_quantity
        : orderLine.rejected_quantity;

    return {
      id: orderLine.id,
      receive_good_quantity:
        orderLine.carried_quantity - receivedGoodQtySubtractor,
      receive_damaged_quantity: orderLine.receive_damaged_quantity,
      final_good_product_price: orderLine.final_good_product_price,
      final_damaged_product_price: orderLine.final_damaged_product_price,
      rejected_quantity: Number(rejectedQty.toFixed(2)),
      rejected_reason_type: rejectedReasonType,
      rejected_reason_detail: orderLine.rejected_reason_detail,
    };
  });

  const payload = { attachments, order_lines: orderLines };

  if (isEnableGeoLocation) {
    const coords = geoFetchLocationFromStorage(deliveryId);
    return {
      ...payload,
      latitude: coords?.lat ?? null,
      longitude: coords?.lng ?? null,
    };
  }

  return payload;
};

export const transformSuccessPayloadPickup = (
  formValues: ToSuccessFormValues,
  deliveryId?: string,
  isEnableGeoLocation?: boolean,
): ToSuccessTransformedFormValues => {
  const attachments = formValues.attachments?.filter(
    attachment => attachment.link,
  );
  const orderLines = formValues.order_lines.map(orderLine => {
    return {
      id: orderLine.id,
      receive_good_quantity: orderLine.receive_good_quantity,
      receive_damaged_quantity: orderLine.receive_damaged_quantity,
      final_good_product_price: orderLine.final_good_product_price,
      final_damaged_product_price: orderLine.final_damaged_product_price,
      rejected_quantity: 0,
      rejected_reason_type: orderLine.rejected_reason_type,
      rejected_reason_detail: orderLine.rejected_reason_detail,
    };
  });

  const payload = { attachments, order_lines: orderLines };

  if (isEnableGeoLocation) {
    const coords = geoFetchLocationFromStorage(deliveryId);
    return {
      ...payload,
      latitude: coords?.lat ?? null,
      longitude: coords?.lng ?? null,
    };
  }

  return payload;
};
