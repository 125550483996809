import {
  BaseRecord,
  HttpError,
  useCreate,
  useInvalidate,
} from '@refinedev/core';
import {
  geoFetchLocationFromStorage,
  geoRemoveLocationFromStorage,
} from 'hooks/useGeoLocation/helper';
import { PaymentNextStatusPayload } from 'pages/types';
import { OrderDetail } from 'types';

type UseUpdateToNextStatusProps = {
  deliveryDetail?: OrderDetail;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  retryRequest?: (_: any, error: any) => boolean;
  onSuccessCallback?: () => void;
  isEnableGeoLocation?: boolean;
};
export const useUpdateToNextStatus = ({
  deliveryDetail,
  retryRequest,
  onSuccessCallback,
  isEnableGeoLocation,
}: UseUpdateToNextStatusProps) => {
  const { mutate: finishOrder, isLoading: isFinishing } = useCreate<
    BaseRecord,
    HttpError,
    PaymentNextStatusPayload
  >({
    mutationOptions: {
      retry: retryRequest ?? 0,
    },
  });

  const invalidate = useInvalidate();

  const generatePayload = () => {
    const payload = {
      skip_previous_step: false,
      remarks_type: '',
      remarks: '',
      update_to_hard_cash: true,
    };

    if (isEnableGeoLocation) {
      const coords = geoFetchLocationFromStorage(deliveryDetail?.id);
      return {
        ...payload,
        latitude: coords?.lat ?? null,
        longitude: coords?.lng ?? null,
      };
    }

    return payload;
  };

  const updateToNextStatus = () => {
    finishOrder(
      {
        resource: `driver/shipping-order/outbound/${deliveryDetail?.id}/status/delivery-success`,
        values: generatePayload(),
        successNotification: {
          type: 'success',
          message: 'Berhasil update status order',
          description: `Order ${deliveryDetail?.so_number} dalam tahap pelunasan`,
        },
      },
      {
        onSuccess: () => {
          if (isEnableGeoLocation) {
            geoRemoveLocationFromStorage(deliveryDetail?.id);
          }
          invalidate({
            resource: `driver/shipping-order/outbound`,
            invalidates: ['all'],
          });
          onSuccessCallback?.();
        },
      },
    );
  };

  return { updateToNextStatus, isFinishing, generatePayload };
};
