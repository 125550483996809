import {
  useCreate,
  useInvalidate,
  type BaseRecord,
  type HttpError,
} from '@refinedev/core';
import { geoRemoveLocationFromStorage } from 'hooks/useGeoLocation/helper';
import type { ToSuccessFormValues } from 'hooks/useDeliveryForm/types';
import { transformSuccessPayloadPickup as transformSuccessPayload } from 'hooks/useDeliveryForm/helpers';
import type { SubmitHandler, UseFormSetError } from 'react-hook-form';
import type { OrderDetail } from 'types';
import { validateTotalCarriedQuantity } from '../helpers';

export const usePickupOnDelivery = (
  deliveryDetail: OrderDetail,
  setError: UseFormSetError<ToSuccessFormValues>,
  isEnableGeoLocation?: boolean,
) => {
  const { mutate, isLoading } = useCreate<BaseRecord, HttpError>();

  const invalidate = useInvalidate();

  const updateToNextStatus: SubmitHandler<ToSuccessFormValues> = formValues => {
    const isInvalid = validateTotalCarriedQuantity(
      formValues,
      setError,
      deliveryDetail.business_type,
    );
    if (!isInvalid) {
      mutate(
        {
          resource: `driver/shipping-order/${deliveryDetail.id}/status/delivery-success`,
          values: transformSuccessPayload(
            formValues,
            deliveryDetail.id,
            isEnableGeoLocation,
          ),
          successNotification: {
            type: 'success',
            message: 'Berhasil update status order',
            description: `Status order ${deliveryDetail.so_number} menjadi Selesai`,
          },
        },
        {
          onSuccess: () => {
            if (isEnableGeoLocation) {
              geoRemoveLocationFromStorage(deliveryDetail?.id);
            }
            invalidate({
              resource: `driver/shipping-order`,
              invalidates: ['all'],
            });
          },
        },
      );
    }
  };

  return {
    isLoading,
    updateToNextStatus,
  };
};
