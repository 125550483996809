import { Box, Image, Text } from '@efishery/onefish';
import { Card } from 'components';
import type { ShipmentType } from 'types';

type AttachmentProps = {
  attachmentType: string;
  shipmentType: ShipmentType | undefined;
  src: string | undefined;
  'data-testid-prefix'?: string;
};

export const Attachment = ({
  attachmentType,
  shipmentType,
  src,
  'data-testid-prefix': dataTestIdPrefix = '',
}: AttachmentProps) => {
  const isShowAttachment = (type?: ShipmentType) => {
    if (type === 'LAST_MILE') {
      return true;
    }
    return false;
  };

  if (isShowAttachment(shipmentType)) {
    return (
      <Card p="4">
        <Text
          fontSize="md"
          fontWeight="bold"
          data-testid={`${dataTestIdPrefix}txt_attachment_label`}
        >
          {attachmentType}
        </Text>
        {(src ?? '').length > 0 ? (
          <Image src={src} alt={attachmentType} />
        ) : (
          <Box borderWidth="1px" borderRadius="lg" p={4} mt={2} color="gray">
            Tidak ada {attachmentType.toLowerCase()}
          </Box>
        )}
      </Card>
    );
  }

  return null;
};
