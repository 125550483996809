import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  NumberInput,
  NumberInputField,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import { comodityDefectWord } from 'features/transport/utils';
import { ToSuccessFormValues } from 'hooks/useDeliveryForm/types';
import { useState } from 'react';
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  useFormContext,
} from 'react-hook-form';
import { OrderDetail } from 'types';

type AdditionalFormProps = {
  errors: FieldErrors<ToSuccessFormValues>;
  register: UseFormRegister<ToSuccessFormValues>;
  deliveryDetail: OrderDetail;
  index: number;
  setValue: UseFormSetValue<ToSuccessFormValues>;
  trigger: UseFormTrigger<ToSuccessFormValues>;
};

type IsAcceptedState = '0' | '1';

export const AdditionalForm = ({
  errors,
  register,
  setValue,
  index,
  trigger,
  deliveryDetail,
}: AdditionalFormProps) => {
  const [isAccepted, setIsAccepted] = useState<IsAcceptedState>('0');

  const { getValues } = useFormContext<ToSuccessFormValues>();

  const handleChange = (value: IsAcceptedState) => {
    setIsAccepted(value);
    if (value === '0') {
      setValue(`order_lines.${index}`, {
        ...getValues(`order_lines.${index}`),
        receive_damaged_quantity: 0,
      });
    }
  };

  const receivedDamagedQty = Boolean(
    errors?.order_lines?.[index]?.receive_damaged_quantity,
  );
  const damagedProductPrice = Boolean(
    errors?.order_lines?.[index]?.final_damaged_product_price,
  );

  return (
    <>
      <FormControl my="2">
        <HStack>
          <FormLabel fontSize="sm" m="auto 0">
            {'Yang ' +
              comodityDefectWord(deliveryDetail?.business_type) +
              ' diambil / tidak?'}
          </FormLabel>
          <RadioGroup defaultValue="0" onChange={handleChange}>
            <HStack>
              <Radio id="accepted-dead-fish" value="1">
                Ya
              </Radio>
              <Radio id="rejected-dead-fish" value="0">
                Tidak
              </Radio>
            </HStack>
          </RadioGroup>
        </HStack>
      </FormControl>

      {isAccepted === '1' && (
        <>
          <FormControl isInvalid={receivedDamagedQty}>
            <FormLabel fontSize="sm">Jumlah yang diambil</FormLabel>
            <InputGroup>
              <NumberInput w="full">
                <NumberInputField
                  data-testid={`txt_fld_received_damage_qty_${index}`}
                  borderEndRadius="none"
                  {...register(
                    `order_lines.${index}.receive_damaged_quantity`,
                    {
                      required: 'Tidak boleh kosong!',
                      max: {
                        value: Number(
                          getValues(`order_lines.${index}.damaged_quantity`),
                        ),
                        message: 'Tidak boleh melebihi jumlah kondisi mati',
                      },
                      min: {
                        value: 0.1,
                        message: 'Jumlah yang diambil wajib diisi',
                      },
                      valueAsNumber: true,
                      onBlur: () => {
                        trigger(
                          `order_lines.${index}.receive_damaged_quantity`,
                        );
                      },
                    },
                  )}
                />
              </NumberInput>
              <InputRightAddon children="kg" />
            </InputGroup>
            <FormErrorMessage>
              {errors?.order_lines?.[index]?.receive_damaged_quantity?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={damagedProductPrice}>
            <FormLabel fontSize="sm">Harga</FormLabel>
            <InputGroup>
              <InputLeftAddon children="Rp" />
              <NumberInput w="full" precision={2}>
                <NumberInputField
                  disabled
                  borderStartRadius="none"
                  {...register(
                    `order_lines.${index}.final_damaged_product_price`,
                    {
                      required: 'Tidak boleh kosong!',
                      valueAsNumber: true,
                      onBlur: () => {
                        trigger(
                          `order_lines.${index}.final_damaged_product_price`,
                        );
                      },
                    },
                  )}
                  style={{ color: 'black' }}
                />
              </NumberInput>
            </InputGroup>
            <FormErrorMessage>
              {
                errors?.order_lines?.[index]?.final_damaged_product_price
                  ?.message
              }
            </FormErrorMessage>
          </FormControl>
        </>
      )}
    </>
  );
};
