import { useEffect, useState } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { DamageState, ToSuccessFormValues } from 'hooks/useDeliveryForm/types';
import { Attachment } from 'types';

type UseRegenerateRRProps = {
  receivingReport: Attachment | undefined;
  control: Control<ToSuccessFormValues>;
};

type GeneratedReceivedQty = {
  receivedGoodQty: number;
  receivedDamagedQty: number;
  damageState?: DamageState;
}[];

const defaultReceivedQty = [
  { receivedGoodQty: 0, receivedDamagedQty: 0, damageState: undefined },
];

export const useRegenerateRR = ({
  receivingReport,
  control,
}: UseRegenerateRRProps) => {
  const [shouldRegenerateRR, setShouldRegenerateRR] = useState(false);
  const [generatedReceivedQty, setGeneratedReceivedQty] =
    useState<GeneratedReceivedQty>(defaultReceivedQty);

  const watchOrderLines = useWatch({ name: 'order_lines', control });

  useEffect(() => {
    // Force user to regenerate RR on input change by
    // disabling `Selesaikan Pengiriman` button and
    // adjusting generate RR button copy
    if (receivingReport) {
      const isAnyQtyChanged = watchOrderLines.some((orderLine, index) => {
        const isReceivedGoodQtyChanged =
          orderLine.receive_good_quantity !==
          generatedReceivedQty[index].receivedGoodQty;

        const isReceivedDamagedQtyChanged =
          orderLine.receive_damaged_quantity !==
          generatedReceivedQty[index].receivedDamagedQty;

        if (isReceivedGoodQtyChanged || isReceivedDamagedQtyChanged) {
          return true;
        }
      });

      const isAnyDamageStateChanged = watchOrderLines.some(
        (orderLine, index) => {
          return (
            orderLine.damage_state !== generatedReceivedQty[index].damageState
          );
        },
      );

      if (isAnyQtyChanged) {
        setShouldRegenerateRR(true);
      } else {
        setShouldRegenerateRR(false);
      }

      if (isAnyDamageStateChanged) {
        setShouldRegenerateRR(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchOrderLines]);

  return {
    shouldRegenerateRR,
    setShouldRegenerateRR,
    setGeneratedReceivedQty,
  };
};
