import { InvoiceStatus, ShipmentType } from 'types';

export const composeInvoiceStatusMsg = (status?: InvoiceStatus) => {
  switch (status) {
    case 'CREATE_SUCCESS':
      return 'Menunggu pembayaran';
    case 'FETCH_INVOICE_LINE_ERROR':
      return 'Gagal mengambil data tagihan';
    case 'CREATE_ERROR':
      return 'Tagihan gagal dibuat';
    case 'CREATE_IN_PROGRESS':
      return 'Tagihan sedang dibuat';
    case 'PAID':
      return 'Sudah dibayar';
    default:
      return 'Tagihan tidak diketahui';
  }
};

export const isShowInvoice = (
  type?: ShipmentType,
  invoiceStatus?: InvoiceStatus,
) => {
  if (type === 'LAST_MILE' && invoiceStatus !== 'CREATE_SUCCESS') {
    return false;
  }
  if (type === 'LAST_MILE') {
    return true;
  }
  return false;
};

export const isAbleToResyncInvoice = (
  isSyncError: boolean,
  status?: InvoiceStatus,
) => {
  if (status === 'CREATE_SUCCESS') {
    return false;
  }

  if (isSyncError) {
    return false;
  }

  return true;
};

export const isInvoiceExist = (status: InvoiceStatus) => {
  if (status === 'CREATE_SUCCESS') {
    return true;
  }

  return false;
};
