import React from 'react';
import { Box, Flex } from '@efishery/onefish';
import { useNavigation, useResource } from '@refinedev/core';
import { NavItem } from '../NavItem';

export const NavBar = () => {
  const { resources, identifier } = useResource();
  const { list } = useNavigation();

  const renderNavs: React.ReactElement[] = [];
  for (const resource of resources) {
    if (resource?.meta?.bottomNav) {
      renderNavs.push(
        <NavItem
          key={resource.name}
          title={resource?.meta?.label || ''}
          icon={resource?.meta?.icon as JSX.Element}
          active={resource.name === identifier}
          onClick={() => list(resource.name)}
        />,
      );
    }
  }

  return (
    <Box
      w="full"
      height="60px"
      display="flex"
      bg="white"
      position="sticky"
      bottom="0"
      zIndex="1"
    >
      <Box w="full">
        <Flex justifyContent="space-around" data-testid="box_navigation">
          {renderNavs}
        </Flex>
      </Box>
    </Box>
  );
};
