import dayjs from 'dayjs';

type SaveToDeviceProps = {
  data: string | Blob;
  type: string;
  fileName: string;
};

export const saveToDevice = ({ data, type, fileName }: SaveToDeviceProps) => {
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = fileName;

  document.body.appendChild(a);
  a.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export const generateFileName = (originalFileName: string) => {
  return `${dayjs().unix()}-${originalFileName}`;
};