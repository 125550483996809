import { Loading } from 'components';
import { Link } from 'react-router-dom';
import { Button } from '@efishery/onefish';
import { useGetPhoneNumber } from 'hooks';
import { trackReceivingReport } from 'utils';

type ReceivingReportProps = {
  link: string | undefined;
  loading?: boolean;
  label?: string;
  'data-testid-prefix'?: string;
};

export const ReceivingReport = ({
  label = 'Download (RR)',
  link,
  loading,
  'data-testid-prefix': dataTestIdPrefix = '',
}: ReceivingReportProps) => {
  const phone = useGetPhoneNumber();
  if (loading) {
    return <Loading />;
  }

  if (link) {
    return (
      <Link
        hrefLang="id"
        to={link}
        target="_blank"
        data-testid={`${dataTestIdPrefix}link_receiving_report`}
        onClick={() => {
          trackReceivingReport('download_receipt', { phone_number: phone });
        }}
      >
        <object data={link} type="application/pdf" width="100%" />
        <Button size="md" variant="link" width="full">
          {label}
        </Button>
      </Link>
    );
  }
  return null;
};
