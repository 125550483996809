import {
  type BaseRecord,
  type HttpError,
  useCreate,
  useInvalidate,
} from '@refinedev/core';
import type { OrderDetail } from 'types';

export const usePickupDraft = (deliveryDetail: OrderDetail) => {
  const { mutate, isLoading } = useCreate<BaseRecord, HttpError>();
  const invalidate = useInvalidate();

  const updateToNextStatus = () => {
    mutate(
      {
        resource: `driver/shipping-order/${deliveryDetail.id}/status/at-origin`,
        values: {},
        successNotification: {
          type: 'success',
          message: 'Berhasil update status order',
          description: `Status order ${deliveryDetail.so_number} menjadi Muat Barang`,
        },
      },
      {
        onSuccess: () => {
          invalidate({
            resource: `driver/shipping-order`,
            invalidates: ['all'],
          });
        },
      },
    );
  };

  return {
    isLoading,
    updateToNextStatus,
  };
};
