import { Flex, Link, Text } from '@efishery/onefish';
import { Copyable } from 'components';

type PaymentLinkProps = {
  link: string | undefined;
  'data-testid-prefix'?: string;
};

export const PaymentLink = ({
  link,
  'data-testid-prefix': dataTestIdPrefix = '',
}: PaymentLinkProps) => {
  return (
    <Flex justifyContent="space-between" alignItems="start" w="full" gap="2">
      <Text fontSize="sm" color="grey.500">
        Payment Link
      </Text>
      <Flex alignItems="start" justifyContent="end" w="65%" gap="2">
        {link ? (
          <Link fontSize="sm" href={link} isExternal>
            <Text
              textAlign="right"
              style={{ hyphens: 'auto', hyphenateCharacter: '' }}
              data-testid={`${dataTestIdPrefix}txt_payment_va_link`}
            >
              {link}
            </Text>
          </Link>
        ) : (
          <Text
            fontSize="sm"
            data-testid={`${dataTestIdPrefix}txt_empty_payment_va_link`}
          >
            -
          </Text>
        )}
        <Copyable value={link} />
      </Flex>
    </Flex>
  );
};
