import { Box, Button, Image, VStack } from '@efishery/onefish';
import { CheckCircle, Trash } from '@onefish/icons-react';

type SuccessProps = {
  imageUrl?: string;
  onClickDelete: () => void;
  isDisabled?: boolean;
};

export const Success = ({
  imageUrl,
  onClickDelete,
  isDisabled,
}: SuccessProps) => {
  return (
    <Box
      py="3"
      px="4"
      border="1px"
      borderColor="grey.200"
      borderStyle="dashed"
      borderRadius="lg"
    >
      <VStack w="full" spacing="4">
        {imageUrl ? (
          <Image src={imageUrl} boxSize="200px" fit="cover" />
        ) : (
          <Box color="brand.500" bg="brand.70" borderRadius="full">
            <CheckCircle size="40" weight="fill" />
          </Box>
        )}

        <Button
          colorScheme="grey"
          variant="outline"
          width="full"
          leftIcon={<Trash size="18" />}
          onClick={onClickDelete}
          isDisabled={isDisabled}
        >
          Hapus foto
        </Button>
      </VStack>
    </Box>
  );
};
