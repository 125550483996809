import { Center, Spinner } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useAuth } from '@efishery/sdk-reactjs';

export const Login = () => {
  const auth = useAuth();

  useEffect(() => {
    auth.login();
  }, [auth]);

  return (
    <Center h="100vh">
      <Spinner color="brand.500" />
    </Center>
  );
};
