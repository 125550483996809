import { isInvoiceExist } from 'components';
import { OrderDetail } from 'types';
import { currencyIntl } from 'utils';

export const showFinalPrice = (deliveryDetail?: OrderDetail) => {
  if (!deliveryDetail) return '-';

  if (isInvoiceExist(deliveryDetail.invoice_status)) {
    return currencyIntl(deliveryDetail.invoice_grand_total_nett) || '-';
  }

  return currencyIntl(deliveryDetail.total_final_price_gross) || '-';
};

export const showInvoiceNumber = (deliveryDetail?: OrderDetail) => {
  if (!deliveryDetail) return '-';

  if (isInvoiceExist(deliveryDetail.invoice_status)) {
    return deliveryDetail.invoice_number;
  }

  return '-';
};

export const showOrderQuantity = (deliveryDetail?: OrderDetail) => {
  if (!deliveryDetail) return '-';

  if (isInvoiceExist(deliveryDetail.invoice_status)) {
    const uniqueProductCodes = deliveryDetail.invoice_lines.reduce(
      (acc, invoiceLine) => {
        if (!acc[invoiceLine.product_code]) {
          acc[invoiceLine.product_code] = true;
        }
        return acc;
      },
      {} as { [key: string]: boolean },
    );

    return Object.keys(uniqueProductCodes).length;
  }

  return deliveryDetail.order_lines.length;
};
