import { Box, BoxProps } from '@efishery/onefish';

export const Card = ({ children, ...otherProps }: BoxProps) => {
  return (
    <Box
      borderRadius="lg"
      shadow="md"
      overflow="hidden"
      bg="white"
      {...otherProps}
    >
      {children}
    </Box>
  );
};
