import { useToast } from '@efishery/onefish';
import {
  generateFileName,
  getReceiptMessage,
  saveToDevice,
  sendWhatsApp,
} from 'utils';

type UsePaymentReceiptActionProps = {
  receiptUrl: string | undefined;
  whatsAppNumber: string;
  orderId: string;
  referenceNumber: string;
  invoiceNumber: string | undefined;
  total: number | undefined;
};

export const usePaymentReceiptAction = ({
  receiptUrl,
  whatsAppNumber,
  orderId,
  referenceNumber,
  invoiceNumber,
  total,
}: UsePaymentReceiptActionProps) => {
  const toast = useToast();

  const displayError = (action: string) => {
    toast({
      status: 'error',
      position: 'top-right',
      title: `Gagal ${action}`,
      description: 'Bukti pembayaran tidak tersedia',
    });
  };

  const downloadReceipt = async () => {
    if (!receiptUrl) {
      displayError('menyimpan sebagai PDF');
      return;
    }

    const res = await fetch(receiptUrl);
    const pdfData = await res.blob();
    saveToDevice({
      data: pdfData ?? '',
      fileName: generateFileName('BUKTI-BAYAR-COD-EFISHERY'),
      type: 'application/pdf',
    });
  };

  const shareViaWhatsApp = () => {
    if (!receiptUrl) {
      displayError('membagikan via WhatsApp');
      return;
    }
    const message = getReceiptMessage({
      orderId,
      referenceNumber,
      invoiceNumber,
      total,
      receiptUrl,
    });
    sendWhatsApp(whatsAppNumber, message);
  };

  return {
    downloadReceipt,
    shareViaWhatsApp,
  };
};
