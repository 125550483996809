import { useForm } from 'react-hook-form';
import { PageType, ToSuccessFormValues } from './types';
import { BaseRecord, CreateResponse, HttpError } from '@refinedev/core';
import {
  getOnDeliveryDefaultValuesProxy,
  transformSuccessPayloadDelivery,
  transformSuccessPayloadPickup,
} from './helpers';
import { Attachment, OrderDetail, OrderLinePayload } from 'types';
import { useState } from 'react';

const usePickupOnDeliveryForm = (deliveryDetail: OrderDetail) => {
  const [receivingReport, setReceivingReport] = useState<Attachment>();
  const pickupForm = useForm<
    ToSuccessFormValues,
    HttpError,
    ToSuccessFormValues
  >({
    defaultValues: getOnDeliveryDefaultValuesProxy('PICKUP', deliveryDetail),
  });

  const orderLines =
    transformSuccessPayloadPickup(pickupForm.getValues())?.order_lines ??
    ([] as OrderLinePayload[]);

  return {
    formInstance: pickupForm,
    receivingReport,
    orderLines,
    onSuccessGenerateRRCallback: (
      resp: CreateResponse<BaseRecord & Attachment>,
    ) => {
      setReceivingReport(resp?.data);
      pickupForm.setValue('attachments.0', {
        link: resp?.data?.link,
        type: 'RECEIVING_REPORT',
      });
      pickupForm.clearErrors('attachments.0.link');
    },
    onErrorGenerateRRCallback: () => {
      setReceivingReport(undefined);
      pickupForm.setValue('attachments.0', {
        link: '',
        type: 'RECEIVING_REPORT',
      });
    },
  };
};

const useOnDeliveryForm = (deliveryDetail: OrderDetail) => {
  const [receivingReport, setReceivingReport] = useState<Attachment>();

  const formInstance = useForm<
    ToSuccessFormValues,
    HttpError,
    ToSuccessFormValues
  >({
    defaultValues: getOnDeliveryDefaultValuesProxy('DELIVERY', deliveryDetail),
  });

  const orderLines =
    transformSuccessPayloadDelivery(formInstance.getValues())?.order_lines ??
    ([] as OrderLinePayload[]);

  return {
    formInstance,
    receivingReport,
    orderLines,
    onSuccessGenerateRRCallback: (
      resp: CreateResponse<BaseRecord & Attachment>,
    ) => {
      setReceivingReport(resp?.data);
      formInstance.setValue('attachments.0', {
        link: resp?.data?.link,
        type: 'RECEIVING_REPORT',
      });
      formInstance.clearErrors('attachments.0.link');
    },
    onErrorGenerateRRCallback: () => {
      setReceivingReport(undefined);
      formInstance.setValue('attachments.0', {
        link: '',
        type: 'RECEIVING_REPORT',
      });
    },
  };
};

export const useDeliveryForm = (
  pageType: PageType,
  deliveryDetail: OrderDetail,
) => {
  const proxy = {
    DELIVERY: useOnDeliveryForm,
    PICKUP: usePickupOnDeliveryForm,
  };
  return proxy[pageType](deliveryDetail);
};
