import { Button, Text, VStack } from '@efishery/onefish';

type ActionToastDescriptionProps = {
  text: string;
  action: string;
  onClick: () => void;
};

const ActionToastDescription = ({
  text,
  action,
  onClick,
}: ActionToastDescriptionProps) => {
  return (
    <VStack align="end">
      <Text>{text}</Text>
      <Button
        colorScheme="red"
        bgColor="white"
        variant="outline"
        onClick={onClick}
        size="sm"
      >
        {action}
      </Button>
    </VStack>
  );
};

export default ActionToastDescription;
