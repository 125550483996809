import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  InputGroup,
  InputRightAddon,
  NumberInput,
  NumberInputField,
  Text,
  VStack,
} from '@efishery/onefish';
import {
  Address,
  Card,
  ComodityDescription,
  ConsignmentNoteButton,
  Description,
  GeoPopover,
  ReceivingReport,
} from 'components';
import {
  comodityDefectWord,
  comodityFineWord,
  generateCta,
} from 'features/transport/utils';
import {
  useDeliveryForm,
  useGenerateRR,
  useGeoLocation,
  useRegenerateRR,
} from 'hooks';
import { geoSavePosition } from 'hooks/useGeoLocation/helper';
import { FormEvent, useEffect, useState } from 'react';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { OrderDetail } from 'types';
import { getAttachmentLink } from 'utils/order';
import { formatShipDate } from '../../helpers';
import { composePickupMessage, validateTotalCarriedQuantity } from '../helpers';
import { usePickupOnDelivery } from '../hooks/usePickupOnDelivery';

type PickupOnDeliveryProps = {
  deliveryDetail: OrderDetail;
  'data-testid-prefix'?: string;
  isEnableGeoLocation?: boolean;
};

export const PickupOnDelivery = ({
  deliveryDetail,
  'data-testid-prefix': dataTestIdPrefix = '',
  isEnableGeoLocation,
}: PickupOnDeliveryProps) => {
  const [isDisabledGenRR, setIsDisabledGenRR] = useState(true);

  const {
    formInstance: methods,
    receivingReport,
    orderLines,
    onSuccessGenerateRRCallback,
    onErrorGenerateRRCallback,
  } = useDeliveryForm('PICKUP', deliveryDetail);

  const {
    setError,
    handleSubmit,
    register,
    trigger,
    watch,
    getValues,
    control,
    formState: { errors },
  } = methods;

  const pageName = 'pickup-on-delivery';
  const {
    isShouldShowPopover,
    onTogglePopover,
    onClosePopover,
    isLocationActive,
    ...geoProps
  } = useGeoLocation({
    positionKey: deliveryDetail.id,
    pageName,
  });

  const { isLoading, updateToNextStatus } = usePickupOnDelivery(
    deliveryDetail,
    setError,
    isEnableGeoLocation,
  );

  const _persistGeoToStorage = () => {
    navigator.geolocation.getCurrentPosition(position => {
      geoSavePosition(position.coords, deliveryDetail.id, pageName);
      handleSubmit(updateToNextStatus)();
    });
  };

  const submitHandlerWithGeo = (e: FormEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (!isEnableGeoLocation) {
      handleSubmit(updateToNextStatus)();
      return;
    }

    if (isShouldShowPopover()) {
      onTogglePopover();
      return;
    }

    if (document && navigator && isLocationActive()) {
      _persistGeoToStorage();
      return;
    }

    handleSubmit(updateToNextStatus)();
  };

  const { fields } = useFieldArray({
    name: 'order_lines',
    control,
  });

  const { shouldRegenerateRR, setShouldRegenerateRR, setGeneratedReceivedQty } =
    useRegenerateRR({
      control,
      receivingReport,
    });

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if ((name ?? '').indexOf('order_lines') > -1) {
        setIsDisabledGenRR(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [errors, watch]);

  const { generateRR, isLoading: isGenerateRRLoading } = useGenerateRR(
    deliveryDetail,
    orderLines,
    onSuccessGenerateRRCallback,
    onErrorGenerateRRCallback,
    true,
  );

  const generateRRHandler = async () => {
    await trigger('order_lines');
    const isInvalid = validateTotalCarriedQuantity(
      getValues(),
      setError,
      deliveryDetail.business_type,
    );
    if (!errors.order_lines && !isInvalid) {
      generateRR();
    }
    setGeneratedReceivedQty(
      getValues('order_lines').map(orderLine => {
        return {
          receivedGoodQty: orderLine.receive_good_quantity,
          receivedDamagedQty: orderLine.receive_damaged_quantity,
        };
      }),
    );
    setShouldRegenerateRR(false);
  };

  const consignmentNote = getAttachmentLink(
    deliveryDetail.attachments,
    'CONSIGNMENT_NOTE',
  );

  return (
    <>
      <FormProvider {...methods}>
        <VStack
          w="full"
          alignItems="stretch"
          spacing="3"
          mb="6"
          as="form"
          onSubmit={submitHandlerWithGeo}
        >
          <Card p="4">
            <VStack w="full" alignItems="stretch" spacing="3">
              <Heading as="h2" size="md">
                Bongkar Barang
              </Heading>

              <Flex bg="brand.70" borderRadius="md" p="3" color="brand.600">
                <Box w={consignmentNote ? '80%' : '100%'}>
                  <Text fontWeight="semibold">{deliveryDetail.dest_name}</Text>
                </Box>
                <ConsignmentNoteButton link={consignmentNote} />
              </Flex>

              <Text fontSize="md" lineHeight="5" color="grey.500">
                {composePickupMessage(deliveryDetail)}
              </Text>
            </VStack>
          </Card>

          <Address
            address={deliveryDetail.dest_address}
            city={deliveryDetail.dest_city_name}
            province={deliveryDetail.dest_province_name}
          />

          <Card p="4">
            <Description
              name="Status Penjemputan"
              value="Lanjut kirim barang"
            />
          </Card>

          <Card p="4">
            <Description
              name="Tanggal pengantaran"
              value={formatShipDate(deliveryDetail.delivery_at)}
            />
          </Card>

          {fields?.map((orderLine, index) => (
            <Card p="4" key={orderLine.id}>
              <VStack w="full" alignItems="stretch" spacing="2">
                <ComodityDescription
                  name="Jenis"
                  businessType={deliveryDetail?.business_type}
                  value={deliveryDetail.order_lines[index].product_name}
                  data-testid={`${dataTestIdPrefix}txt_product_name-${
                    index + 1
                  }`}
                />
                <ComodityDescription
                  name="Ukuran"
                  businessType={deliveryDetail?.business_type}
                  value={deliveryDetail.order_lines[index].product_name}
                  data-testid={`${dataTestIdPrefix}txt_product_size-${
                    index + 1
                  }`}
                />

                <Divider variant="dashed" />

                <Description
                  name="Jumlah yang Dibawa"
                  value={orderLine.carried_quantity}
                  format="thousand-separator"
                  unit="kg"
                  data-testid={`${dataTestIdPrefix}txt_carried_quantity-${
                    index + 1
                  }`}
                />

                <Divider variant="dashed" />

                <FormControl
                  isInvalid={Boolean(
                    errors?.order_lines?.[index]?.receive_good_quantity,
                  )}
                >
                  <FormLabel fontSize="sm">
                    {'Jumlah ' +
                      comodityFineWord(deliveryDetail?.business_type)}
                  </FormLabel>
                  <InputGroup>
                    <NumberInput w="full">
                      <NumberInputField
                        borderEndRadius="none"
                        {...register(
                          `order_lines.${index}.receive_good_quantity`,
                          {
                            required:
                              'Jumlah ' +
                              comodityFineWord(deliveryDetail?.business_type) +
                              ' wajib diisi',
                            valueAsNumber: true,
                            max: {
                              value: Number(orderLine.carried_quantity),
                              message:
                                'Tidak boleh melebihi jumlah total yang dibawa',
                            },
                            min: {
                              value: 0,
                              message:
                                'Jumlah ' +
                                comodityDefectWord(
                                  deliveryDetail?.business_type,
                                ) +
                                ' invalid',
                            },
                            onBlur: () => {
                              trigger(
                                `order_lines.${index}.receive_good_quantity`,
                              );
                            },
                          },
                        )}
                        data-testid={`${dataTestIdPrefix}txtfld_receive_good_quantity-${
                          index + 1
                        }`}
                      />
                    </NumberInput>
                    <InputRightAddon>kg</InputRightAddon>
                  </InputGroup>
                  <FormErrorMessage
                    data-testid={`${dataTestIdPrefix}txt_error_receive_good_quantity-${
                      index + 1
                    }`}
                  >
                    {
                      errors?.order_lines?.[index]?.receive_good_quantity
                        ?.message
                    }
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={Boolean(
                    errors?.order_lines?.[index]?.receive_damaged_quantity,
                  )}
                >
                  <FormLabel fontSize="sm">
                    {'Jumlah ' +
                      comodityDefectWord(deliveryDetail?.business_type)}
                  </FormLabel>
                  <InputGroup>
                    <NumberInput w="full">
                      <NumberInputField
                        borderEndRadius="none"
                        {...register(
                          `order_lines.${index}.receive_damaged_quantity`,
                          {
                            required:
                              'Jumlah ' +
                              comodityDefectWord(
                                deliveryDetail?.business_type,
                              ) +
                              ' wajib diisi',
                            valueAsNumber: true,
                            max: {
                              value: Number(orderLine.carried_quantity),
                              message:
                                'Tidak boleh melebihi jumlah total yang dibawa',
                            },
                            min: {
                              value: 0,
                              message:
                                'Jumlah ' +
                                comodityDefectWord(
                                  deliveryDetail?.business_type,
                                ) +
                                ' invalid',
                            },
                            onBlur: () => {
                              trigger(
                                `order_lines.${index}.receive_damaged_quantity`,
                              );
                            },
                          },
                        )}
                        data-testid={`${dataTestIdPrefix}txtfld_receive_damaged_quantity-${
                          index + 1
                        }`}
                      />
                    </NumberInput>
                    <InputRightAddon>kg</InputRightAddon>
                  </InputGroup>
                  <FormErrorMessage
                    data-testid={`${dataTestIdPrefix}txt_error_receive_damaged_quantity-${
                      index + 1
                    }`}
                  >
                    {
                      errors?.order_lines?.[index]?.receive_damaged_quantity
                        ?.message
                    }
                  </FormErrorMessage>
                </FormControl>
              </VStack>
            </Card>
          ))}

          <Card p="4">
            <Button
              size={'md'}
              variant="solid"
              width={'full'}
              onClick={() => generateRRHandler()}
              data-testid="btn_generate_rr"
              isLoading={isGenerateRRLoading}
              isDisabled={isDisabledGenRR || Boolean(errors.order_lines)}
            >
              Konfirmasi {shouldRegenerateRR && 'Ulang'} Jumlah Diterima
            </Button>
          </Card>
          <Card p="4">
            <FormControl isInvalid={Boolean(errors?.attachments?.[0]?.link)}>
              <FormLabel fontSize="sm">Surat Receiving Report (RR)</FormLabel>
              <FormErrorMessage>
                {errors.attachments?.[0]?.link?.message}
              </FormErrorMessage>
              <ReceivingReport
                loading={isGenerateRRLoading}
                link={receivingReport?.link}
              />
              <input
                type="hidden"
                {...register('attachments.0.link', {
                  required: 'Tidak boleh kosong!',
                })}
              />
            </FormControl>
          </Card>

          <Button
            width="full"
            type="submit"
            isLoading={isLoading}
            loadingText="Memperbarui status..."
            data-testid="btn_update_status"
            isDisabled={!receivingReport || shouldRegenerateRR}
          >
            {generateCta(deliveryDetail.status, deliveryDetail.shipment_type)}
          </Button>
        </VStack>
      </FormProvider>
      {isEnableGeoLocation && (
        <GeoPopover
          {...geoProps}
          yesHandler={() => {
            _persistGeoToStorage();
            onClosePopover();
          }}
        />
      )}
    </>
  );
};
