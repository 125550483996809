import { Box, VStack } from '@efishery/onefish';
import { Description } from 'components';
import { Format } from 'components/Description';

type DescriptionItem = {
  testId: string;
  name: string;
  value: string;
  isShow?: boolean;
  format?: Format;
};

export const DescriptionList = ({ items }: { items: DescriptionItem[] }) => {
  return (
    <VStack spacing="2" py="2">
      {(items ?? [])?.map(item => {
        if (item.isShow === false) return null;

        return (
          <Box key={btoa(item.name)} width={'full'}>
            <Description
              data-testid={item.testId}
              name={item.name}
              value={item.value}
              format={item.format}
            />
          </Box>
        );
      })}
    </VStack>
  );
};
