import { VStack, Box, Text } from '@efishery/onefish';
import { Card } from 'components';
import { comodity } from 'features/transport/utils';
import { BusinessType } from 'types';
import { startCase } from 'utils';

type OriginSectionProps = {
  origin: string | undefined;
  businessType?: BusinessType;
};

export const OriginSection = ({ origin, businessType }: OriginSectionProps) => {
  if (origin) {
    return (
      <Card p="4">
        <VStack w="full" alignItems="stretch" spacing="3">
          <Box bg="brand.70" borderRadius="md" p="3" color="brand.600">
            <Box>
              <Text fontWeight="semibold">
                Ambil {comodity(businessType)} dari {startCase(origin)}
              </Text>
            </Box>
          </Box>
        </VStack>
      </Card>
    );
  }
  return null;
};
