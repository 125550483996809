import {
  Box,
  Button,
  Center,
  Collapse,
  Heading,
  StackDivider,
  Text,
  VStack,
  useDisclosure,
} from '@efishery/onefish';
import { ArrowDown, ArrowUp } from '@onefish/icons-react';
import { HttpError, useOne, useParsed } from '@refinedev/core';

import { Card, DescriptionList, Empty, ImagePrompt } from 'components';
import { PAYMENT_TYPE_LABEL } from 'features/transport/constants';
import { DetailPengiriman } from 'pages/payment-receipt/components/DetailPengiriman';
import { TotalPembayaran } from 'pages/payment-receipt/components/TotalPembayaran';
import { showInvoiceNumber } from 'pages/payment-receipt/utils/price';
import type { OrderDetail } from 'types';
import { currencyIntl } from 'utils';
import { getAttachmentLink } from 'utils/order';
import { ActionNavBar } from './components/ActionNavBar';

export const PaidShow = () => {
  const { id } = useParsed();

  const { isOpen, onToggle } = useDisclosure();

  const { data } = useOne<OrderDetail, HttpError>({
    resource: '/driver/shipping-order',
    id,
  });

  const deliveryDetail = data?.data;

  if (!deliveryDetail) return <Empty />;

  const descItems = [
    {
      testId: 'txt_payment_type',
      name: 'Metode Pembayaran',
      value: PAYMENT_TYPE_LABEL[deliveryDetail.payment_type] || '-',
      isShow: true,
    },
    {
      testId: 'txt_ref_number',
      name: 'No Referensi',
      value: deliveryDetail?.so_number || '-',
      isShow: showInvoiceNumber(deliveryDetail) !== '-',
    },
    {
      testId: 'txt_description_no_invoice',
      name: 'No. Invoice',
      value: showInvoiceNumber(deliveryDetail),
      isShow: true,
    },
  ];

  const hardCashPaymentReceipt = getAttachmentLink(
    deliveryDetail.attachments,
    'PROOF_OF_PAYMENT_HARD_CASH',
  );

  return (
    <>
      <Box px="3" mb="32">
        {deliveryDetail && (
          <VStack w="full" alignItems="stretch" spacing="3" mb="6">
            <Card p="4">
              <Collapse in={isOpen} animateOpacity startingHeight={360}>
                <ImagePrompt
                  imgUrl="/efishery-prompt-ok.png"
                  subtitle={
                    <Heading>
                      {currencyIntl(
                        deliveryDetail?.total_final_price_nett ?? 0,
                      ) || '-'}
                    </Heading>
                  }
                  title={
                    <Text fontSize="sm" color="grey.500" align={'center'}>
                      Pembayaran Lunas
                    </Text>
                  }
                />
                <DescriptionList items={descItems} />
                <StackDivider borderWidth={1} />
                <Box py={4} px={2}>
                  <DetailPengiriman deliveryDetail={deliveryDetail} />
                  <TotalPembayaran deliveryDetail={deliveryDetail} />
                </Box>
              </Collapse>
              <Box py={2}>
                <Center>
                  {!isOpen ? (
                    <Button
                      onClick={onToggle}
                      variant="link"
                      leftIcon={<ArrowDown size={18} />}
                      data-testid="btn_lihat_lebih_rinci"
                    >
                      Lihat Lebih Rinci
                    </Button>
                  ) : (
                    <Button
                      onClick={onToggle}
                      variant="link"
                      leftIcon={<ArrowUp size={18} />}
                      data-testid="btn_tutup_rincian"
                    >
                      Tutup Rincian
                    </Button>
                  )}
                </Center>
              </Box>
            </Card>
          </VStack>
        )}
      </Box>
      <ActionNavBar
        orderId={deliveryDetail.so_external_id}
        referenceNumber={deliveryDetail.so_number}
        invoiceNumber={deliveryDetail.invoice_number}
        total={deliveryDetail.invoice_grand_total_nett}
        receiptUrl={hardCashPaymentReceipt}
        whatsAppNumber={deliveryDetail.recipient_phone}
      />
    </>
  );
};
