import type { InvoiceLine } from 'types';

type DisplayLine = {
  id: string;
  product_name: string;
  receive_damaged_quantity: number;
  total_good_product_final_price: number;
  receive_good_quantity: number;
  product_uom: string;
  good_product_final_price: number;
  total_damaged_product_final_price: number;
  damaged_product_final_price: number;
};

type TransformInvoiceLinesFn = (
  invoiceLines: InvoiceLine[] | undefined,
) => DisplayLine[];

export const transformInvoiceLines: TransformInvoiceLinesFn = invoiceLines => {
  if (!invoiceLines) {
    return [];
  }

  const productMap: { [key: string]: DisplayLine } = {};

  for (const invoiceLine of invoiceLines) {
    const isDefect = invoiceLine.description.includes('[Defect]');
    const id = invoiceLine.product_code;

    if (!productMap[id]) {
      productMap[id] = {
        id: id,
        product_name: invoiceLine.product_name,
        product_uom: invoiceLine.product_uom,
        receive_damaged_quantity: 0,
        damaged_product_final_price: 0,
        total_damaged_product_final_price: 0,
        receive_good_quantity: 0,
        good_product_final_price: 0,
        total_good_product_final_price: 0,
      };
    }

    if (isDefect) {
      productMap[id].receive_damaged_quantity += invoiceLine.quantity;
      productMap[id].damaged_product_final_price = invoiceLine.price;
      productMap[id].total_damaged_product_final_price +=
        invoiceLine.price_subtotal;
    } else {
      productMap[id].receive_good_quantity += invoiceLine.quantity;
      productMap[id].good_product_final_price = invoiceLine.price;
      productMap[id].total_good_product_final_price +=
        invoiceLine.price_subtotal;
    }
  }

  return Object.values(productMap);
};
