import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useClipboard,
} from '@efishery/onefish';
import { Copy } from '@onefish/icons-react';

type CopyableProps = {
  value?: string;
};

export const Copyable = ({ value = '' }: CopyableProps) => {
  const { onCopy, setValue, hasCopied } = useClipboard(value);

  return (
    <Popover isOpen={hasCopied} size="sm">
      <PopoverTrigger>
        <IconButton
          icon={<Copy />}
          variant="outline"
          size="xs"
          aria-label="Copy Button"
          onClick={() => {
            setValue(value);
            onCopy();
          }}
        />
      </PopoverTrigger>
      <PopoverContent w="auto" bgColor="brand.70" color="brand.600">
        <PopoverArrow bgColor="brand.70" />
        <PopoverHeader fontSize="sm">Tersalin!</PopoverHeader>
      </PopoverContent>
    </Popover>
  );
};
