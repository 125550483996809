import { Image } from '@efishery/onefish';
import { ReceivingReport } from 'components';
import { InvoiceStatus, OrderDetail, ShipmentType } from 'types';

export const renderReceivingReport = (
  fileExt?: string,
  receivingReport?: string,
  deliveryDetail?: OrderDetail,
) => {
  if (fileExt === 'pdf') {
    return <ReceivingReport link={receivingReport} label="Lihat (RR)" />;
  }
  return <Image src={receivingReport} alt={deliveryDetail?.order_id} />;
};

export const isShowInvoice = (
  type?: ShipmentType,
  invoiceStatus?: InvoiceStatus,
) => {
  if (type === 'LAST_MILE' && invoiceStatus !== 'CREATE_SUCCESS') {
    return false;
  }
  if (type === 'LAST_MILE') {
    return true;
  }
  return false;
};
