import React from 'react';
import { Box, Text, VStack } from '@efishery/onefish';

type NavItemProps = {
  title: string;
  icon: JSX.Element;
  active?: boolean;
  onClick: () => void;
  'data-testid'?: string;
};

export const NavItem = ({
  title,
  icon,
  active = false,
  onClick,
  'data-testid': dataTestId = '',
}: NavItemProps) => {
  return (
    <Box
      as="button"
      flex="1"
      height="60px"
      color={active ? 'brand.500' : 'gray.500'}
      onClick={onClick}
      _active={{ bg: 'white' }}
      borderTop="2px"
      borderTopColor={active ? 'brand.500' : 'gray.200'}
      data-testid={dataTestId}
    >
      <VStack spacing="2px">
        {React.cloneElement(icon, { size: '24' })}
        <Text fontWeight="normal" fontSize="xs">
          {title}
        </Text>
      </VStack>
    </Box>
  );
};
