import { Description, Format } from 'components';
import type { BusinessType } from 'types';

type ComodityNameProps = {
  name: string;
  businessType?: BusinessType;
};

export type ComodityDescriptionProps = ComodityNameProps & {
  value?: string | number | null;
  'data-testid'?: string;
  format?: Format;
  unit?: string;
};

const _fullComodity = ({
  name,
  businessType = 'FISH_DOWNSTREAM',
}: ComodityNameProps) => {
  const comodity = businessType === 'FISH_DOWNSTREAM' ? 'Ikan' : 'Udang';

  if (name === 'Total diterima dengan kondisi baik') {
    return 'Total ' + comodity + ' diterima dengan kondisi baik';
  }
  if (name === 'Total diterima dengan kondisi kurang baik') {
    return 'Total ' + comodity + ' diterima dengan kondisi kurang baik';
  }
  if (name === 'Harga dengan kondisi baik') {
    return 'Harga ' + comodity + ' dengan kondisi baik';
  }
  if (name === 'Harga dengan kondisi kurang baik') {
    return 'Harga ' + comodity + ' dengan kondisi kurang baik';
  }
  if (name === 'Total Harga kondisi baik') {
    return 'Total Harga ' + comodity + ' kondisi baik';
  }
  if (name === 'Total Harga kondisi kurang baik') {
    return 'Total Harga ' + comodity + ' kondisi kurang baik';
  }
  return '';
};

const _simpleComodity = ({
  name,
  businessType = 'FISH_DOWNSTREAM',
}: ComodityNameProps) => {
  if (businessType === 'FISH_DOWNSTREAM') {
    return name + ' Ikan';
  }

  if (businessType === 'SHRIMP_DOWNSTREAM') {
    return name + ' Udang';
  }

  return name + ' Ikan';
};

export const ComodityDescription = ({
  name,
  businessType = 'FISH_DOWNSTREAM',
  ...props
}: ComodityDescriptionProps) => {
  const simpleName = ['Jenis', 'Ukuran'];
  if (simpleName.includes(name)) {
    return (
      <Description name={_simpleComodity({ name, businessType })} {...props} />
    );
  }

  const fullName = [
    'Total diterima dengan kondisi baik',
    'Total diterima dengan kondisi kurang baik',
    'Harga dengan kondisi baik',
    'Harga dengan kondisi kurang baik',
    'Total Harga kondisi baik',
    'Total Harga kondisi kurang baik',
  ];
  if (fullName.includes(name)) {
    return (
      <Description name={_fullComodity({ name, businessType })} {...props} />
    );
  }

  return null;
};
