import { useDisclosure } from '@efishery/onefish';
import { useCallback, useEffect, useState } from 'react';
import { GEO_DENIED, GEO_UNAVAILABLE } from './const';
import {
  geoFetchPermission,
  geoSavePermission,
  geoSavePosition,
  isHideInitialPopover,
  isMobile,
  saveLocationQueryToState,
} from './helper';
import { PermissionState } from './types';

type GeoLocationProps = {
  positionKey?: string;
  pageName?: string;
};

export const useGeoLocation = ({ positionKey, pageName }: GeoLocationProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const [geoState, setGeoState] = useState<PermissionState | null>(null);
  const [isShowPopover, setIsShowPopover] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const onClosePopover = () => {
    onClose();
    setIsShowPopover(false);
  };

  const onTogglePopover = () => {
    onToggle();
    setIsShowPopover(true);
  };

  const yesHandler = () => {
    if (document && navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          geoSavePosition(position.coords, positionKey, pageName);
          onClosePopover();
        },

        error => {
          if (error.code === GEO_DENIED) {
            geoSavePermission('denied', pageName);
          }
          if (error.code === GEO_UNAVAILABLE) {
            geoSavePermission('unavailable', pageName);
          }
          onClosePopover();
        },
      );
    }
  };

  const noHandler = () => {
    if (document && localStorage) {
      geoSavePermission('denied', pageName);
      onClosePopover();
    }
  };

  const closeHandler = () => {
    if (document && localStorage) {
      geoSavePermission('prompt', pageName);
      onClosePopover();
    }
  };

  const isShouldShowPopover = () => {
    const localStoragePermission = geoFetchPermission(pageName);
    return (
      geoState === 'prompt' &&
      localStoragePermission !== 'denied' &&
      localStoragePermission !== 'granted'
    );
  };

  const isLocationActive = useCallback(
    (pageName?: string) => {
      if (!pageName) {
        // profile toggle takes precedence
        if (geoFetchPermission('profile') === 'denied') {
          return false;
        }

        return geoState === 'granted';
      }

      if (isMobile()) {
        return (
          geoFetchPermission(pageName) === 'granted' &&
          (geoState === 'granted' || geoState === 'prompt')
        );
      }

      return (
        geoFetchPermission(pageName) === 'granted' && geoState === 'granted'
      );
    },
    [geoState],
  );

  useEffect(() => {
    saveLocationQueryToState(setGeoState);

    if (
      document &&
      navigator &&
      localStorage &&
      !isOpen &&
      !isHideInitialPopover(pageName) &&
      isShouldShowPopover()
    ) {
      onTogglePopover();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoState]);

  useEffect(() => {
    if (!isOpen && !isShowPopover) {
      saveLocationQueryToState(setGeoState);
      setIsChecked(isLocationActive(pageName));
    }
    return () => {
      setIsChecked(false);
    };
  }, [isLocationActive, isShowPopover, pageName, isOpen]);

  return {
    isOpen,
    onToggle,
    yesHandler,
    noHandler,
    closeHandler,
    isShowPopover,
    onTogglePopover,
    isChecked,
    isShouldShowPopover,
    onClosePopover,
    isLocationActive,
  };
};
