import { Attachment, AttachmentType } from 'types';

export const getAttachmentLink = (
  attachments: Attachment[] | null | undefined,
  type: AttachmentType,
) => {
  return (attachments ?? []).find(attachment => {
    return attachment.type === type;
  })?.link;
};
