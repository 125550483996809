import { OrderStatus, ShipmentType } from 'types';
import { DELIVERY_OPTIONS } from '../constants';

export const generateCta = (
  status: OrderStatus,
  shipmentType?: ShipmentType,
): string => {
  if (
    (shipmentType === 'FIRST_MILE' || shipmentType === 'MID_MILE') &&
    status === 'INTRANSIT'
  ) {
    return 'Selesaikan Pengiriman';
  }

  return DELIVERY_OPTIONS[DELIVERY_OPTIONS[status].nextStatus].label;
};
