import { useState } from 'react';

export const useMutationTimeoutHook = (threshold = 10) => {
  const [timeout, setTimeout] = useState<number>(Date.now());

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const retryRequest = (_: any, error: any) => {
    const isOk = error?.statusCode === 200;

    if (isOk) {
      return false;
    }

    if (Date.now() > timeout) {
      return false;
    }

    return true;
  };

  const setThreshold = () => {
    setTimeout(Date.now() + threshold * 1000);
  };

  return { retryRequest, setThreshold };
};
