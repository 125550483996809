import {
  Button,
  ButtonGroup,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  Text,
} from '@efishery/onefish';
import { isWebview } from 'utils';

type GeoPopoverProps = {
  isOpen: boolean;
  onToggle?: () => void;
  yesHandler?: () => void;
  noHandler?: () => void;
  closeHandler?: () => void;
};

export const GeoPopover = ({
  isOpen,
  yesHandler,
  noHandler,
  closeHandler,
}: GeoPopoverProps) => {
  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={closeHandler}
      closeOnBlur={false}
      placement="top-end"
    >
      <PopoverContent>
        <PopoverHeader fontWeight="semibold">Izinkan Lokasi</PopoverHeader>
        <PopoverCloseButton />
        <PopoverBody>Aktifkan lokasi untuk ketepatan pengiriman:</PopoverBody>
        {isWebview(navigator?.userAgent) && (
          <PopoverBody>
            <Text style={{ fontStyle: 'italic' }}>
              Untuk pencatatan lokasi, gunakan aplikasi browser daripada webview
            </Text>
          </PopoverBody>
        )}
        <PopoverFooter display="flex" justifyContent="flex-end">
          <ButtonGroup size="sm">
            <Button variant="outline" colorScheme="grey" onClick={noHandler}>
              Tidak
            </Button>
            <Button colorScheme="observatory" onClick={yesHandler}>
              Ya
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
