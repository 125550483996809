import { FormControl, FormErrorMessage, FormLabel } from '@efishery/onefish';
import { UploadImage } from 'components';

export const UploadSuratJalanField = ({
  name,
  isInvalid,
  message,
}: {
  name: string;
  isInvalid?: boolean;
  message?: string;
}) => {
  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel fontSize="sm">Surat Jalan</FormLabel>
      <UploadImage
        title="surat jalan"
        name={name}
        fileName="consignment_note"
        data-testid="btn_upload_surat_jalan"
        isRequired
      />
      <FormErrorMessage>{message}</FormErrorMessage>
    </FormControl>
  );
};
