import { FormControl, FormErrorMessage, Input, VStack } from '@chakra-ui/react';
import { RequestPaymentFormValues, VirtualAccountVia } from 'pages/types';
import { useFormContext } from 'react-hook-form';

export const RequestPaymentForm = ({
  sendVia = 'WHATSAPP',
}: {
  sendVia?: VirtualAccountVia;
}) => {
  const {
    register,
    formState: { defaultValues, errors },
  } = useFormContext<RequestPaymentFormValues>();

  return (
    <VStack>
      <FormControl my="2">
        <Input {...register('send_via')} hidden defaultValue={sendVia} />
        <Input
          {...register('recepient_phone_number')}
          defaultValue={defaultValues?.recepient_phone_number}
        />
        <FormErrorMessage>
          {errors?.recepient_phone_number?.message}
        </FormErrorMessage>
      </FormControl>
    </VStack>
  );
};
