import type { HttpError, OpenNotificationParams } from '@refinedev/core';
import { transformSuccessPayloadDelivery } from 'hooks/useDeliveryForm/helpers';
import { ToSuccessFormValues } from 'hooks/useDeliveryForm/types';
import type { Order, OrderDetail, OrderStatus } from 'types';
import { formatDateTimeRange, toTitleCase } from 'utils';
import {
  DELIVERY_OPTIONS,
  FISH_INTERNAL_ERROR,
  ORDER_AGGREGATOR_ERROR,
} from '../../features/transport/constants';

type MessageOption = {
  showAddress?: boolean;
};

export const composeDeliveryMessage = (
  delivery: Order | OrderDetail,
  { showAddress = false }: MessageOption = {},
) => {
  const { dest_name, expected_delivery_at, expected_delivery_end_at, status } =
    delivery;

  const subdistrict = toTitleCase(delivery?.dest_subdistrict_name ?? '');
  const district = toTitleCase(delivery?.dest_district_name ?? '');
  const city = toTitleCase(delivery?.dest_city_name ?? '');

  const address = `${delivery.dest_address} ${subdistrict}, ${district}, ${city}`;

  switch (status) {
    case 'READY_TO_ORIGIN':
      return (
        <>
          Untuk pengiriman ke <strong>{dest_name}</strong>
          <br />
          {showAddress && (
            <>
              {address}
              <br />
            </>
          )}
          Sampai di Buyer{' '}
          <em>
            tanggal{' '}
            {formatDateTimeRange(
              expected_delivery_at ?? '',
              expected_delivery_end_at ?? '',
            )}
          </em>
        </>
      );
    case 'AT_ORIGIN':
    case 'INTRANSIT':
    case 'PAYMENT':
    case 'WAITING_FOR_PAYMENT':
    case 'DELIVERY_SUCCESS':
      return (
        <>
          {showAddress && (
            <>
              {address}
              <br />
            </>
          )}
          Sampai di Buyer{' '}
          <em>
            tanggal{' '}
            {formatDateTimeRange(
              expected_delivery_at ?? '',
              expected_delivery_end_at ?? '',
            )}
          </em>
        </>
      );
  }
};

export const formatDeliveryStatus = (status: OrderStatus) => {
  const deliveryStatus = DELIVERY_OPTIONS[status];

  return {
    buttonLabel: DELIVERY_OPTIONS[deliveryStatus.nextStatus].label,
    statusColorScheme: deliveryStatus.colorScheme,
    statusLabel: deliveryStatus.label,
  };
};

export function calcTotalPrice(quantity: number, price: number) {
  return quantity * price;
}

export const validateAllRejected = (formValues: ToSuccessFormValues) => {
  return transformSuccessPayloadDelivery(formValues).order_lines.every(
    orderLine => {
      return (
        orderLine.receive_good_quantity === 0 &&
        orderLine.receive_damaged_quantity === 0
      );
    },
  );
};

export const handleAllowedErrors = (error: HttpError, action: () => void) => {
  if (
    error.message === FISH_INTERNAL_ERROR ||
    error.message === ORDER_AGGREGATOR_ERROR
  ) {
    action();
  }
};

export const errorNotification = (
  error: HttpError | undefined,
): OpenNotificationParams => {
  let description = error?.message;
  if (error) {
    handleAllowedErrors(error, () => {
      if (error.message === FISH_INTERNAL_ERROR) {
        description =
          'Status order terupdate. Namun, terdapat error di Fish Internal Tool Service';
      }
      if (error.message === ORDER_AGGREGATOR_ERROR) {
        description =
          'Status order terupdate. Namun, terdapat error di Order Aggregator';
      }
    });
  }
  return {
    type: 'error',
    message: 'Terjadi kesalahan',
    description,
  };
};
