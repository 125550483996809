import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputRightAddon,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import { comodity, comodityDefectWord } from 'features/transport/utils';
import { ToSuccessFormValues } from 'hooks/useDeliveryForm/types';
import { FieldErrors, UseFormRegister, UseFormTrigger } from 'react-hook-form';
import { BusinessType, OrderDetail, OrderLine } from 'types';
import { startCase } from 'utils';

type TotalDamageFormProps = {
  errors: FieldErrors<ToSuccessFormValues>;
  register: UseFormRegister<ToSuccessFormValues>;
  deliveryDetail: OrderDetail;
  orderLine: OrderLine;
  index: number;
  trigger: UseFormTrigger<ToSuccessFormValues>;
  businessType?: BusinessType;
};

export const TotalDamageForm = ({
  errors,
  register,
  orderLine,
  index,
  trigger,
  businessType,
}: TotalDamageFormProps) => {
  const damagedQty = Boolean(errors?.order_lines?.[index]?.damaged_quantity);

  return (
    <FormControl isInvalid={damagedQty} my="3">
      <FormLabel fontSize="sm">
        {'Total ' +
          comodity(businessType) +
          ' Kondisi ' +
          startCase(comodityDefectWord(businessType))}
      </FormLabel>
      <InputGroup>
        <NumberInput w="full">
          <NumberInputField
            data-testid={`txt_fld_damage_qty_${index}`}
            borderEndRadius="none"
            {...register(`order_lines.${index}.damaged_quantity`, {
              required: 'Tidak boleh kosong!',
              max: {
                value: Number(orderLine.carried_quantity),
                message: 'Tidak boleh melebihi jumlah total yang dibawa',
              },
              min: {
                value: 0.1,
                message:
                  'Total ' +
                  comodity(businessType) +
                  ' yang ' +
                  comodityDefectWord(businessType) +
                  ' tidak boleh kosong',
              },
              valueAsNumber: true,
              onBlur: () => {
                trigger(`order_lines.${index}.damaged_quantity`);
              },
            })}
          />
        </NumberInput>
        <InputRightAddon children="kg" />
      </InputGroup>
      <FormErrorMessage>
        {errors?.order_lines?.[index]?.damaged_quantity?.message}
      </FormErrorMessage>
    </FormControl>
  );
};
