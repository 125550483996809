import { Box, Flex } from '@efishery/onefish';
import { DownloadSimple, WhatsappLogo } from '@onefish/icons-react';
import { NavItem } from 'components';
import { usePaymentReceiptAction } from 'hooks';

type PaymentReceiptNavBarProps = {
  receiptUrl: string | undefined;
  whatsAppNumber: string;
  orderId: string;
  referenceNumber: string;
  invoiceNumber: string | undefined;
  total: number | undefined;
  'data-testid-prefix'?: string;
};

export const PaymentReceiptNavBar = ({
  'data-testid-prefix': dataTestIdPrefix = '',
  ...props
}: PaymentReceiptNavBarProps) => {
  const { downloadReceipt, shareViaWhatsApp } = usePaymentReceiptAction(props);

  return (
    <Box
      w="full"
      maxW="md"
      height="60px"
      display="flex"
      bg="white"
      position="fixed"
      bottom="0"
      zIndex="1"
    >
      <Box w="full">
        <Flex justifyContent="space-around" data-testid="box_navigation">
          <NavItem
            title="Simpan sebagai PDF"
            icon={<DownloadSimple />}
            onClick={downloadReceipt}
            data-testid={`${dataTestIdPrefix}btn_save_as_pdf`}
          />
          <NavItem
            title="Bagikan via WhatsApp"
            icon={<WhatsappLogo />}
            onClick={shareViaWhatsApp}
            data-testid={`${dataTestIdPrefix}btn_share_via_whatsapp`}
          />
        </Flex>
      </Box>
    </Box>
  );
};
