import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  VStack,
} from '@efishery/onefish';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { SkipPaymentForm } from './SkipPaymentForm';
import { PaymentNextStatusPayload } from 'pages/types';
import {
  BaseRecord,
  HttpError,
  useCreate,
  useInvalidate,
} from '@refinedev/core';
import type { OrderDetail } from 'types';
import {
  geoFetchLocationFromStorage,
  geoRemoveLocationFromStorage,
} from 'hooks/useGeoLocation/helper';

type SkipReasonModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  setThreshold: () => void;
  retryRequest: (_: unknown, error: unknown) => boolean;
  deliveryDetail?: OrderDetail;
  isEnableGeoLocation?: boolean;
};

export const SkipReasonModal = ({
  isOpen,
  onClose,
  onCancel,
  setThreshold,
  retryRequest,
  deliveryDetail,
  isEnableGeoLocation,
}: SkipReasonModalProps) => {
  const invalidate = useInvalidate();

  const skipPaymentForm = useForm<
    PaymentNextStatusPayload,
    HttpError,
    PaymentNextStatusPayload
  >({
    defaultValues: {
      remarks_type:
        deliveryDetail?.payment_type === 'TERMS'
          ? 'SKIP_PAYMENT_TYPE_TERM'
          : '',
    },
  });

  const { mutate: finishOrder, isLoading } = useCreate<
    BaseRecord,
    HttpError,
    PaymentNextStatusPayload
  >({
    mutationOptions: {
      retry: retryRequest,
    },
  });

  const generatePayload = (formValues: PaymentNextStatusPayload) => {
    const payload = {
      ...formValues,
      skip_previous_step: true,
      update_to_hard_cash: false,
    };

    if (isEnableGeoLocation) {
      const coords = geoFetchLocationFromStorage(deliveryDetail?.id);
      return {
        ...payload,
        latitude: coords?.lat ?? null,
        longitude: coords?.lng ?? null,
      };
    }

    return payload;
  };

  const skipPaymentFormHandler: SubmitHandler<
    PaymentNextStatusPayload
  > = async formValues => {
    const resource = `driver/shipping-order/outbound/${deliveryDetail?.id}/status/delivery-success`;
    await setThreshold();
    await finishOrder(
      {
        resource,
        values: generatePayload(formValues),
        successNotification: {
          type: 'success',
          message: 'Berhasil update status order',
          description: `Status order ${deliveryDetail?.so_number} menjadi Selesai`,
        },
      },
      {
        onSuccess: () => {
          if (isEnableGeoLocation) {
            geoRemoveLocationFromStorage(deliveryDetail?.id);
          }
          invalidate({
            resource: 'driver/shipping-order',
            invalidates: ['all'],
          });
          onClose();
        },
      },
    );
  };

  return (
    <FormProvider {...skipPaymentForm}>
      <Drawer
        isOpen={isOpen}
        placement="bottom"
        onClose={() => {
          onClose();
          skipPaymentForm.reset();
        }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <VStack
            w="full"
            maxW="md"
            marginX="auto"
            position="relative"
            align="stretch"
            as="form"
            key="2"
            onSubmit={skipPaymentForm.handleSubmit(skipPaymentFormHandler)}
          >
            <DrawerCloseButton />
            <DrawerHeader data-testid={`txt_skip_reason_modal_title`}>
              Alasan
            </DrawerHeader>
            <DrawerBody>
              <SkipPaymentForm />
            </DrawerBody>
            <DrawerFooter>
              <VStack width="full">
                <Button
                  width="full"
                  backgroundColor={'white'}
                  _hover={{ backgroundColor: 'brand.500', color: 'white' }}
                  color={'brand.400'}
                  borderColor={'brand.400'}
                  border={'2px'}
                  onClick={onCancel}
                >
                  Batal
                </Button>
                <Button
                  type="submit"
                  width="full"
                  data-testid="btn_popup_skip_payment"
                  isLoading={isLoading}
                >
                  Submit
                </Button>
              </VStack>
            </DrawerFooter>
          </VStack>
        </DrawerContent>
      </Drawer>
    </FormProvider>
  );
};
